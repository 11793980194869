import { LinearProgressLoading } from 'app/components/LinearProgressLoading'
import { LoadingContext } from 'app/context/loading'
import { useState } from 'react'

export function LoadingProvider(props: any) {
  const [loading, setLoading] = useState(false)

  return (
    <LoadingContext.Provider
      value={{
        showLoading: () => setLoading(true),
        hideLoading: () => setLoading(false)
      }}
    >
      {loading && <LinearProgressLoading />}
      {props.children}
    </LoadingContext.Provider>
  )
}