import { Controller } from 'react-hook-form'
import { Select, SelectProps } from '../FormUI/Select'

interface SelectControlProps extends SelectProps {
  name: any
  control?: any
  options: any
  label: string
  onHandleChange?: (e: any) => void
  disabled?: boolean
}

export function SelectControl(props: SelectControlProps) {
  const { name, control, options, disabled, ...restProps } = props

  return (
    <>
      {control ? (
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <Select
                disabled={disabled}
                error={error}
                options={options}
                {...field}
                {...restProps}
              />
            )
          }}
        />
      ) : (
        <Select options={options} {...restProps} />
      )}
    </>
  )
}
