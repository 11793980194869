/**
 * Asynchronously loads the component for HomePage
 */

import React from 'react'
import { lazyLoad } from 'utils/loadable';
import { LoadingWrapper } from 'app/components/LoadingWrapper'
import { LoadingIndicator } from 'app/components/LoadingIndicator'
 
export const Home = lazyLoad(
  () => import('./index'),
  module => module.Home,
  {
    fallback: (
      <LoadingWrapper>
        <LoadingIndicator />
      </LoadingWrapper>
    ),
  },
);
