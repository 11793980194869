import { SkillEmployee, SkillEmployeeCreate } from 'app/types/skillEmployee'

import { ListParams } from './../types/common'
import {
  Skill,
  SkillCategory,
  SkillExperience,
  SkillLevel,
  SkillTagCategory,
} from './../types/skill'
import axiosClient from './api'

export const skillApi = {
  // skill level
  getSkillLevels: async (): Promise<SkillLevel[]> => {
    const response = await axiosClient.get('/skill-levels')
    return response.data
  },

  // skill experiences
  getSkillExperiences: async (): Promise<SkillExperience[]> => {
    const response = await axiosClient.get('/skill-experiences')
    return response.data
  },

  // skill tag category
  getSkillTagCategories: async (): Promise<SkillTagCategory[]> => {
    const response = await axiosClient.get('/skill-tag-categories')
    return response.data
  },

  // skill category
  getSkillCategories: async (
    payload?: ListParams,
  ): Promise<SkillCategory[]> => {
    const response = await axiosClient.get('/skill-categories', {
      params: payload,
    })
    return response.data
  },

  // skill
  getSkills: async (): Promise<Skill[]> => {
    const response = await axiosClient.get('/skills')
    return response.data
  },

  // employee skill
  getEmployeeSkill: async (): Promise<SkillEmployee[]> => {
    const response = await axiosClient.get('/skill')
    return response.data
  },

  createEmployeeSkill: async (data: SkillEmployeeCreate) => {
    const response = await axiosClient.post('/skill', data)
    return response.data
  },

  deleteEmployeeSkill: async (id: number) => {
    const response = await axiosClient.delete('/skill/' + id)
    return response.data
  },
}
