import { InputHTMLAttributes } from 'react'
import { Controller } from 'react-hook-form'
import { FormInput } from '../FormUI/FormInput'

interface InputControlProps extends InputHTMLAttributes<HTMLInputElement> {
  name: any
  icon?: any
  control?: any
  label?: string
  defaultValue?: any
}

export function InputControl(props: InputControlProps) {
  const { name, control, icon, onChange, defaultValue, ...restProps } = props

  return (
    <>
      {control ? (
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormInput
              icon={icon}
              error={error}
              {...field}
              onChange={e => {
                onChange?.(e)
                field.onChange(e)
              }}
              {...restProps}
            />
          )}
        />
      ) : (
        <FormInput name={name} onChange={onChange} {...restProps} icon={icon} defaultValue={defaultValue}/>
      )}
    </>
  )
}
