import { ROUTES } from 'app/config/routes'
import { useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { getAccessToken } from 'utils/auth'
import { Header } from '../partials/Header'
import { Sidebar } from '../Sidebar'

export function DefaultLayout() {
  const isAuth = !!getAccessToken()
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return !isAuth 
  ? <Navigate to={ROUTES.AUTH.LOGIN} />
  : (
    <>
      <div className="min-h-full">
        {/* Static sidebar for desktop */}
        <Sidebar />
        {/* Main column */}
        <div className="flex flex-col lg:pl-64">
          {/* Header Mobile */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          {/* Show content */}
          <Outlet />
        </div>
      </div>
    </>
  )
}