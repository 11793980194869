import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { TableCell } from '@mui/material'

interface Props {
  title: string
  className?: string
  colspan?: number
}
export function TableHeaderContent(props: Props) {
  const { colspan, title, className } = props
  return (
    <th
      scope="col"
      colSpan={colspan}
      className={`px-3 py-3.5 text-left text-sm font-bold text-black ${className}`}
    >
      {title}
    </th>
  )
}

export function TableHeaderContentMore(props: TableProps) {
  const { children } = props
  return (
    <th scope="col" className={`relative w-12 px-6 sm:w-16 sm:px-8`}>
      {children}
    </th>
  )
}

interface TableProps {
  children?: any
  className?: string
  colspan?: number
}
export function Table(props: TableProps) {
  const { children } = props
  return (
    <table className="min-w-full table-fixed divide-y divide-gray-300">
      {children}
    </table>
  )
}

export function TableHeader(props: TableProps) {
  const { children } = props
  return (
    <thead className="bg-gray-300 border-gray-400">
      <tr>{children}</tr>
    </thead>
  )
}

export function TableFooterContent(props: TableProps) {
  const { children, colspan } = props
  return (
    <TableCell
      colSpan={colspan}
      className="!text-base !px-[12px] !text-black-500"
    >
      {children}
    </TableCell>
  )
}

export function TableBody(props: TableProps) {
  const { children } = props
  return <tbody className="divide-y divide-gray-200 bg-white">{children}</tbody>
}

export function TableBodyTr(props: TableProps) {
  const { children } = props
  return <tr className="bg-gray-100 border-gray-200">{children}</tr>
}

interface TableBodyContentProps extends TableProps {
  isSpan?: boolean
  isActive?: boolean
}
export function TableBodyContent(props: TableBodyContentProps) {
  const { className, isSpan, isActive, children } = props
  return (
    <td className={`px-3 py-4 text-sm text-gray-700 ${className}`}>
      {isSpan ? (
        <span
          className={`inline-flex rounded-ful px-2 text-xs font-semibold leading-5 ${
            isActive ? 'text-green-800 bg-green-100' : 'text-red-800 bg-red-100'
          }`}
        >
          {children}
        </span>
      ) : (
        children
      )}
    </td>
  )
}

interface TableBodyImageProps extends TableProps {
  imageUrl?: string
  value?: string
  email?: string
  isChild?: boolean
  onClick?: any
  check?: boolean
}
export function TableBodyImage(props: TableBodyImageProps) {
  const {
    className,
    children,
    imageUrl,
    value,
    email,
    isChild,
    onClick,
    check,
  } = props

  return (
    <td className={`py-4 pl-4 pr-3 text-sm sm:pl-6 ${className}`}>
      <>
        <div className="flex items-center">
          <Image url={imageUrl ?? ''} />
          <div className="ml-4">
            {isChild ? (
              <div
                onClick={onClick}
                className={`font-medium text-gray-900 cursor-pointer flex gap-3`}
              >
                {value}
                {check ? (
                  <ChevronUpIcon width={20} />
                ) : (
                  <ChevronDownIcon width={20} />
                )}
              </div>
            ) : (
              <div className="font-medium text-gray-900">{value}</div>
            )}
            <div className="text-gray-500">{email}</div>
          </div>
        </div>
        {children}
      </>
    </td>
  )
}

interface ImageProps {
  url: string
}
export function Image(props: ImageProps) {
  const { url } = props
  return (
    <div className="h-10 w-10 flex-shrink-0">
      <img className="h-10 w-10 rounded-full" src={url} alt="Error" />
    </div>
  )
}
