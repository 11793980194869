import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { setHeader } from 'app/services/api'
import { authApi } from 'app/services/authApi'
import { RootState } from 'app/store'
import { ListParams } from 'app/types/common'
import { Employee } from 'app/types/employee'

interface AuthState {
  user: Partial<Employee> | null
}

const initialState: AuthState = {
  user: null,
}

export const login = createAsyncThunk(
  'auth/login',
  async (payload: ListParams, { rejectWithValue }) => {
    try {
      return await authApi.login(payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const logout = createAsyncThunk(
  'auth/logout',
  async (payload, { rejectWithValue }) => {
    try {
      return authApi.logout()
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const sendEmail = createAsyncThunk(
  'sendEmail',
  async (data: ListParams, { rejectWithValue }) => {
    try {
      return await authApi.sendMailResetPassword(data)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const resetPassword = createAsyncThunk(
  'resetPassword',
  async (data: ListParams, { rejectWithValue }) => {
    try {
      return await authApi.resetPassword(data)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    checkLogin: state => {
      state = Object.assign(state, {
        isAuth: !!JSON.parse(localStorage.getItem('auth.token') || ''),
      })
    },
  },
  extraReducers: builder => {
    builder.addCase(login.fulfilled , (state, action) => {
      const { payload } = action
      setHeader(payload.token)
    })
    builder.addCase(logout.fulfilled, () => {
      setHeader('')
    })
  },
})

export const selectCurrentUser = (state: RootState) => state.auth.user
export const { checkLogin } = authSlice.actions
export default authSlice.reducer
