import { Button } from 'app/components/Button'
import { InputControl } from 'app/components/FormControl'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useLoading } from 'app/context/loading'
import { useAppDispatch } from 'app/hooks'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTES } from 'app/config/routes'
import { login } from 'features/auth/authSlice'
import { useState } from 'react'
import { ListParams } from 'app/types/common'

export default function Login() {
  const { t } = useTranslation()
  const { showLoading, hideLoading } = useLoading()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [remember, setRemember] = useState(false)

  const initialValues = {
    email: '',
    password: '',
    remember: '',
  }

  const validations = yup.object().shape({
    email: yup
      .string()
      .required(
        t('validation.required', {
          field: t('label.login.email'),
        }),
      )
      .email(t('validation.email')),
    password: yup.string().required(
      t('validation.required', {
        field: t('label.login.password'),
      }),
    ),
    remember: yup.string().nullable(),
  })

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(validations),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: initialValues,
  })

  const handleOnSubmit = async (data: ListParams) => {
    data.remember = remember
    showLoading()
    const response = await dispatch(login(data))
    toast.success(response.payload.message)
    navigate(ROUTES.HOME.INDEX)
    hideLoading()
  }

  return (
    <>
      <Helmet>
        <title>{t('pages.login.title')}</title>
      </Helmet>
      <div className="flex min-h-full items-center justify-center py-14 px-4 sm:px-6 lg:px-8 ">
        <div className="w-full max-w-md space-y-8 p-10 space-y-6 rounded-2xl border border-solid border-[#E5E7EB] box-border shadow-2xl">
          <div>
            <img
              className="mx-auto w-auto h-full"
              src="/assets/img/main-logo.png"
              alt="KZ PORTAL"
            />
          </div>
          <form
            onSubmit={handleSubmit(handleOnSubmit)}
            className="mt-8 space-y-6"
          >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md">
              <div className="mb-3">
                <InputControl
                  control={control}
                  name="email"
                  label={t('label.login.email')}
                />
              </div>
              <InputControl
                control={control}
                type="password"
                name="password"
                label={t('label.login.password')}
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center cursor-pointer">
                <input
                  onClick={() => setRemember(!remember)}
                  id="remember-me"
                  name="remember"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  {t('label.login.remember')}
                </label>
              </div>
            </div>

            <div>
              <Button
                type="submit"
                variant="default"
                className="!ml-0 flex justify-center w-full"
              >
                {t('common.button.login')}
              </Button>
            </div>
            <div className='text-center'>
              <Link
                to={ROUTES.AUTH.FORGET_PASSWORD.INDEX}
                className="text-cyan-800 font-bold hover:text-gray-700"
              >
                {t('label.login.forget_password')}
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
