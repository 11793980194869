import { InputControl } from 'app/components/FormControl'
import { SELECT_GENDER_OPTION } from 'app/constants/global'
import { PRAddress } from 'app/types/address'
import { Employee } from 'app/types/employee'
import { useTranslation } from 'react-i18next'
import { DateFormat } from 'utils/helper'

interface CUREmployeeProps {
  disabled?: boolean
  control: any
  getValues: any
  setValue: any
}

export const getDataPost = (initialValues: Employee) => {
  return {
    ...initialValues,
  }
}

export default function CUREmployee(props: CUREmployeeProps) {
  const { t } = useTranslation()
  const { control, disabled, getValues } = props

  const getTextAddress = (data: PRAddress[] | undefined) => {
    if (!data) return ''

    if (data.length === 0) return ''

    return (
      data[0].pivot.detail_address +
      ' , ' +
      data[0].name +
      ' , ' +
      data[0].district.name +
      ' , ' +
      data[0].district.city?.name
    )
  }

  return (
    <>
      {/* INFO */}
      <div className="grid grid-flow-row grid-cols-2 gap-x-5 gap-y-3">
        <InputControl
          label={t(`common.label.first_name`)}
          control={control}
          name="first_name"
          disabled={disabled}
          className="py-3"
          required={true}
        />

        <InputControl
          label={t(`common.label.last_name`)}
          control={control}
          name="last_name"
          disabled={disabled}
          className="py-3"
          required={true}
        />
      </div>

      <div className="grid grid-flow-row grid-cols-3 gap-x-5 gap-y-3 mt-3">
        <InputControl
          label={t(`common.label.birthday`)}
          name="birthday"
          type={disabled ? 'text' : 'date'}
          value={DateFormat(getValues('birthday'))}
          disabled={disabled}
          className="py-3"
        />

        {
          <InputControl
            label={t(`common.label.job_title`)}
            value={control._defaultValues?.job_title?.name || ''}
            name="job_title.name"
            disabled={disabled}
            readOnly={true}
            className={'py-3'}
          />
        }

        {
          <InputControl
            label={t(`common.label.gender`)}
            name="gender"
            value={
              SELECT_GENDER_OPTION.slice(1, 3)[control._defaultValues.gender]
                .label
            }
            disabled={disabled}
            readOnly={true}
            className="py-3"
          />
        }
      </div>

      {/* IDENTIFY CARD */}
      <div className="grid grid-flow-row grid-cols-3 gap-x-5 gap-y-3 mt-3">
        <InputControl
          type="number"
          label={t(`common.label.identify_card_number`)}
          name="identify_card_number"
          control={control}
          disabled={disabled}
          className="py-3"
        />

        <InputControl
          label={t(`common.label.issued_place`)}
          name="issued_place"
          disabled={disabled}
          className="py-3"
          control={control}
        />

        <InputControl
          label={t(`common.label.issued_on`)}
          name="issued_on"
          value={DateFormat(getValues('birthday'))}
          disabled={disabled}
          className="py-3"
          type={disabled ? 'text' : 'date'}
        />
      </div>

      {/* START & END DATE */}
      <div className="grid grid-flow-row grid-cols-2 gap-x-5 gap-y-3 mt-3">
        <InputControl
          type={disabled ? 'text' : 'date'}
          label={t(`common.label.start_date`)}
          name="start_date"
          value={DateFormat(getValues('start_date'))}
          disabled={disabled}
          className="py-3"
        />

        <InputControl
          label={t(`common.label.end_date`)}
          name="end_date"
          type={disabled ? 'text' : 'date'}
          disabled={disabled}
          className="py-3"
          value={DateFormat(getValues('end_date'))}
        />
      </div>

      {/* INFO */}
      <div className="grid grid-flow-row grid-cols-2 gap-x-5 gap-y-3 mt-3">
        <InputControl
          type="text"
          label={t(`common.label.phone_number`)}
          name="phone_number"
          control={control}
          disabled={disabled}
          className="py-3"
        />

        <InputControl
          type="number"
          label={t(`common.label.tax_code`)}
          name="tax_code"
          control={control}
          disabled={disabled}
          className="py-3"
        />
      </div>

      {/* SOCIAL */}
      <div className="grid grid-flow-row grid-cols-2 gap-x-5 mt-3">
        <InputControl
          type="number"
          label={t(`common.label.dependents_deduct_personal_income`)}
          name="dependents_deduct_personal_income"
          control={control}
          disabled={disabled}
          className="py-3"
        />

        <InputControl
          type="number"
          label={t(`common.label.social_insurance_code`)}
          name="social_insurance_code"
          control={control}
          disabled={disabled}
          className="py-3"
        />
      </div>

      {/* BANKING */}
      <div className="grid grid-flow-row grid-cols-3 gap-x-5 gap-y-3 mt-3">
        {
          <InputControl
            label={t(`common.label.bank`)}
            control={control}
            name="bank.name"
            disabled={disabled}
            readOnly={true}
            className="py-3"
          />
        }

        <InputControl
          type="number"
          label={t(`common.label.bank_account_number`)}
          name="bank_account_number"
          control={control}
          disabled={disabled}
          className="py-3"
        />

        <InputControl
          type="text"
          label={t(`common.label.bank_account_name`)}
          name="bank_account_name"
          control={control}
          disabled={disabled}
          className="py-3"
        />
      </div>

      {/* ADDRESS */}
      {
        <div className="grid grid-flow-row grid-cols-1 gap-y-3 gap-x-5 mt-3">
          <InputControl
            label={t(`common.label.permanent_address`)}
            value={getTextAddress(control._defaultValues.permanent_address)}
            name="permanent_address"
            readOnly={true}
            disabled={disabled}
            className="py-3"
          />

          <InputControl
            label={t(`common.label.present_address`)}
            value={getTextAddress(control._defaultValues.present_address)}
            name="present_address"
            readOnly={true}
            disabled={disabled}
            className="py-3"
          />
        </div>
      }
    </>
  )
}
