import './App.css'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next';
import { Routes, Route } from "react-router-dom"
import { routes } from './routes'
import CommonLayout from 'app/layouts/CommonLayout'

function App() {
  const { i18n } = useTranslation();

  const renderRoutes = (routeItems: any) => {
    const result = routeItems.map((route: any, index: any) => {
      const { element: Element, childrens, ...rest } = route
      const props = { ...rest, element: <Element /> }
      const Layout = route.layout || CommonLayout


      return (
        <Route key={index} element={<Layout />}>
          <Route key={index} {...props}>
            {childrens && renderRoutes(childrens)}
          </Route>
        </Route> 
      )
    })

    return result
  }

  return (
    <>
      <Helmet
        titleTemplate="%s - HELPO-Telemedicine"
        defaultTitle="HELPO-Telemedicine"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="HELPO-Telemedicine" />
      </Helmet>
      <Routes>
        {renderRoutes(routes)}
      </Routes>
    </>
  )
}

export default App
