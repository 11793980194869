import { httpStatus } from "app/constants/common"
import { OptionSelect } from "app/types/common"
import moment from 'moment'
import { toast } from "react-toastify"
export function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export function QueryFetch(queryKey: string[], queryFn: any, enabled: boolean, onSuccess?: any, initialData?: any) {
  return {
    queryKey: queryKey,
    queryFn: queryFn,
    staleTime: 200,
    cacheTime: Infinity,
    retry: false,
    enabled: enabled,
    initialDataUpdatedAt: 200,
    onSuccess: onSuccess,
    initialData: initialData,
  }
}

export function getOptionSelect(data: any | undefined, labelDefault: string) {
  const dataOption: OptionSelect[] = []

  // add option select all
  dataOption.push({ value: '', label: labelDefault })

  if (!data) return dataOption

  // add option
  return dataOption.concat(
    data.map((value: any) => ({
      value: value.id.toString(),
      label: value.name,
    })),
  )
}

export function timeCompare(start_date: string, end_date: string) {
  const startDate = new Date(start_date)
  const endDate = new Date(end_date)
  return startDate > endDate
}

export function handleSetError(
  e: any,
  messageFailed: string,
  setError: (field: string, content: any) => void,
) {
  const {
    meta: { code, error_message: errors },
  } = e as any
  if (code === httpStatus.HTTP_UNPROCESSABLE_ENTITY) {
    setErrorField(errors, setError)
  } else {
    toast.error(messageFailed)
  }
}

export function setErrorField(
  errors: any,
  setError: (field: string, content: any) => void,
) {
  for (const field in errors) {
    const error = errors[field]
    if (typeof error === 'string') {
      setError(field, { type: 'custom', message: error })
      break
    }

    if (Array.isArray(error) && typeof error[0] === 'string') {
      setError(field, { type: 'custom', message: error[0] })
      break
    }
  }
}

export const DateFormat = (date: Date | string) => {
  return date ? moment(date).format('DD/MM/YYYY') : ''
}

export const fullNameEmployee = (last_name?: string, first_name?: string) => {
  return last_name && first_name ? first_name + ' ' + last_name : 'Admin'
}

export function getYearOption() {
  const dataOption: OptionSelect[] = []

  const currentYear = new Date().getFullYear();
  const sixYearsAgo = currentYear - 6;
  const sixYearsLate = currentYear + 6;
  const betweenYears = sixYearsLate - sixYearsAgo;

  for (let index = 1; index < betweenYears; index++) {
    const year = sixYearsAgo + index;
    dataOption.push({ value: year.toString(), label: `${year}` })
  }

  return dataOption
}