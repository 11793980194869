/* eslint-disable jsx-a11y/anchor-is-valid */
import { Menu, Transition } from '@headlessui/react'
import { useQuery } from '@tanstack/react-query'
import { profileApi } from 'app/services/api/profileApi'
import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { MenuSidebar } from '../MenuSidebar'

export function Sidebar(): JSX.Element {
  const navigate = useNavigate()
  // fetch data employees
  const { data: employee, isLoading } = useQuery(
    ['employee'],
    () => profileApi.getEmployee(),
    {
      onError: () => navigate('/employee-info'),
      refetchOnWindowFocus: false,
    },
  )

  return (
    <>
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col lg:border-r lg:border-gray-200 lg:bg-gray-100 lg:pt-5 lg:pb-4 ">
        <div className="flex flex-shrink-0 items-center px-6">
          <img
            className="h-8 w-auto"
            src="/assets/img/main-logo.png"
            alt="Your Company"
          />
        </div>
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="mt-6 flex h-0 flex-1 flex-col overflow-y-auto">
          {/* User account dropdown */}
          <Menu
            as="div"
            className="relative inline-block px-3 text-left mt-1 ml-2"
          >
            <div>
              <span className="flex w-full items-center justify-between">
                <span className="flex min-w-0 items-center justify-between space-x-3">
                  <img
                    className="w-10 h-10 rounded"
                    src={employee?.avatar}
                    alt=""
                  />
                  <span className="flex min-w-0 flex-1 flex-col">
                    <span className="truncate text-sm font-medium text-gray-900">
                      {isLoading || !employee
                        ? '...Loading'
                        : `${employee.last_name} ${employee.first_name}`}
                    </span>
                    <span className="truncate text-sm text-gray-500">
                      {isLoading || !employee
                        ? '...Loading'
                        : `${employee.account.email}`}
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            ></Transition>
          </Menu>
          {/* Navigation */}
          <nav className="mt-16 px-3 ">
            <div className="space-y-1">
              <MenuSidebar />
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}
