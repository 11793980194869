import { Button } from 'app/components/Button'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import { InputControl } from 'app/components/FormControl'
import { ListParams } from 'app/types/common'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useLoading } from 'app/context/loading'
import { useAppDispatch } from 'app/hooks'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'app/config/routes'
import { resetPassword } from 'features/auth/authSlice'


export default function ResetPassword() {
  const { t } = useTranslation()
  const { showLoading, hideLoading } = useLoading()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()


  const validations = yup.object().shape({
    code: yup
      .string()
      .required(t('validation.required', { field: t('common.label.code_reset_password') })),
    password: yup
      .string()
      .required(t('validation.required', { field: t('common.label.password') })),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], t('validation.confirm', { field: t('common.label.password') }))
      .required(t('validation.required', { field: t('common.label.password_confirmation') })),
  })

  const initialValues: ListParams = {
    code: '',
    password: '',
    password_confirmation: ''
  }

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(validations),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: initialValues,
  })

  const handleOnSubmit = async (data: ListParams) => {
    showLoading()
    const response = await dispatch(resetPassword(data))
    toast.success(response.payload.message)
    navigate(ROUTES.HOME.INDEX)
    hideLoading()
  }

  return (
    <>
      <Helmet>
        <title>{t('pages.reset_password.title')}</title>
      </Helmet>
      <div className="flex min-h-full items-center justify-center py-14 px-4 sm:px-6 lg:px-8 ">
        <div className="w-full max-w-md space-y-8 p-10 space-y-6 rounded-2xl border border-solid border-[#E5E7EB] box-border shadow-2xl">
          <div>
            <img
              className="mx-auto w-auto h-full"
              src="/assets/img/main-logo.png"
              alt="KZ PORTAL"
            />
          </div>
          <p className="sub-title mt-0 mb-4 tracking-[0px] text-center">
            {t('pages.reset_password.description')}
          </p>
          <form
            onSubmit={handleSubmit(handleOnSubmit)}
            className="mt-8 space-y-6"
          >
            <div className="-space-y-px rounded-md">
              <div className="mb-3">
                <InputControl
                  control={control}
                  name="code"
                  label={t('common.label.code_reset_password')}
                />
              </div>
            </div>
            <div className="-space-y-px rounded-md">
              <div className="mb-3">
                <InputControl
                  control={control}
                  name="password"
                  label={t('common.label.password')}
                  type="password"
                />
              </div>
            </div>
            <div className="-space-y-px rounded-md">
              <div className="mb-3">
                <InputControl
                  control={control}
                  name="password_confirmation"
                  label={t('common.label.password_confirmation')}
                  type="password"
                />
              </div>
            </div>
            <div>
              <Button
                type="submit"
                variant="default"
                className="!ml-0 flex justify-center w-full mt-10"
              >
                {t('common.button.update')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
