import { TextareaHTMLAttributes } from 'react'
import { Controller } from 'react-hook-form'
import { FormTextarea } from '../FormUI/FormTextarea'

interface TextareaControlProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: any
  control?: any
  label?: string
  defaultValue?: any
  requiredIcon?: boolean
  textColor?: 'black' | string
}

export function TextareaControl(props: TextareaControlProps) {
  const { name, control, requiredIcon, defaultValue, ...restProps } = props

  return (
    <>
      {control ? (
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormTextarea
              error={error}
              requiredIcon={requiredIcon}
              {...field}
              {...restProps}
            />
          )}
        />
      ) : (
        <FormTextarea name={name} requiredIcon={requiredIcon} {...restProps} defaultValue={defaultValue}/>
      )}
    </>
  )
}
