interface InformationRowProps {
  title: string 
  data: string | undefined
}

export default function InformationRow(props: InformationRowProps) {
  const { title, data } = props

  return (
    <div className="min-w-full py-2 align-middle md:px-6 lg:px-8 flex flex-row">
      <h1 className="text-lg font-bold w-1/6 text-right">
        {title}
      </h1>
      <h1 className="text-lg pl-40">
        {data}
      </h1>
    </div>
  )
}
