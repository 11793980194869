import { PlusCircleIcon } from '@heroicons/react/20/solid'
import { StarIcon, TrashIcon } from '@heroicons/react/24/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQueries } from '@tanstack/react-query'
import { Breadcrumb } from 'app/components/BreadCrumb'
import { Button } from 'app/components/Button'
import { SelectControl } from 'app/components/FormControl/SelectControl'
import { useLoading } from 'app/context/loading'
import { skillApi } from 'app/services/skillApi'
import {
  Skill,
  SkillCategory,
  SkillExperience,
  SkillLevel,
  SkillTagCategory,
} from 'app/types/skill'
import { SkillEmployee, SkillEmployeeCreate } from 'app/types/skillEmployee'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { QueryFetch, handleSetError } from 'utils/helper'
import * as yup from 'yup'

export default function SkillPage() {
  const { t } = useTranslation()
  const [skillTagCategory, setSkillTagCategory] = useState(0)
  const [skillCategory, setSkillCategory] = useState(0)
  const [skills, setSkills] = useState<Skill[]>([])
  const { showLoading, hideLoading } = useLoading()

  const validations = yup.object().shape({
    skill_id: yup.string().required(
      t('validation.required', {
        field: t('pages.skill.skill'),
      }),
    ),

    skill_level_id: yup.string().required(
      t('validation.required', {
        field: t('pages.skill.skill_level'),
      }),
    ),

    skill_experience_id: yup.string().required(
      t('validation.required', {
        field: t('pages.skill.skill_experience'),
      }),
    ),
  })

  const { control, handleSubmit, reset, setError } = useForm({
    resolver: yupResolver(validations),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: {
      skill_tag_category: '',
      skill_category: '',
      skill_id: '',
      skill_level_id: '',
      skill_experience_id: '',
    },
  })

  const handleOnSubmit = (formValues: any) => {
    Object.assign(formValues, {
      last_used: null,
      comment: null,
      is_main: false,
    })

    showLoading()
    mutationCreate.mutate(formValues)
  }

  // eslint-disable-next-line no-empty-pattern
  const [
    { data: skillTagCategories, isLoading: isLoadingSTG },
    { data: skillCategories, isLoading: isLoadingSC, refetch: refetchSC },
    { data: skillLevels, isLoading: isLoadingSL },
    { data: skillExperiences, isLoading: isLoadingSE },
    { data: employeeSkill, refetch: refetchES },
  ] = useQueries({
    queries: [
      QueryFetch(['skillTagCategories'], skillApi.getSkillTagCategories, true),
      QueryFetch(
        ['skillCategories', `${skillTagCategory}`],
        () =>
          skillApi.getSkillCategories({
            skill_tag_category_id:
              skillTagCategory === 0 ? null : skillTagCategory,
          }),
        false,
        () => hideLoading(),
        [],
      ),
      QueryFetch(['skillLevels'], skillApi.getSkillLevels, true),
      QueryFetch(['skillExperiences'], skillApi.getSkillExperiences, true),
      QueryFetch(['employeeSkill'], skillApi.getEmployeeSkill, true),
    ],
  })

  const mutationDelete = useMutation({
    mutationFn: (id: number) => {
      return skillApi.deleteEmployeeSkill(id)
    },

    onSuccess: e => {
      hideLoading()
      toast.success(`${e.messages}`)
      refetchES()
    },
    onError: (e: any) => {
      hideLoading()
      handleSetError(e, t('messages.error.update'), setError as any)
    },
  })

  const onHandleDelete = async (id: number) => {
    if (!window.confirm(`${t('label.modal.title')}`)) return

    showLoading()
    mutationDelete.mutate(id)
  }

  const mutationCreate = useMutation({
    mutationFn: (data: SkillEmployeeCreate) => {
      return skillApi.createEmployeeSkill(data)
    },

    onSuccess: e => {
      hideLoading()
      toast.success(`${e.messages}`)
      refetchES()
    },
    onError: (e: any) => {
      hideLoading()
      handleSetError(e, t('messages.error.update'), setError as any)
    },
  })

  useEffect(() => {
    refetchSC()
    reset({
      skill_category: '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillTagCategory])

  useEffect(() => {
    if (skillCategory === 0) {
      setSkillTagCategory(0)
      setSkills([])
      return
    }

    setSkills(
      (skillCategories as SkillCategory[]).find(
        value => value.id === skillCategory,
      )?.skills ?? [],
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillCategory])

  useEffect(() => {
    setSkills(
      (skillCategories as SkillCategory[]).find(
        value => value.id === skillCategory,
      )?.skills ?? [],
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillCategories])

  if (isLoadingSTG || isLoadingSL || isLoadingSC || isLoadingSE) return <></>

  return (
    <>
      <Helmet>
        <title>{t('pages.skill.title')}</title>
      </Helmet>
      <main className="flex-1">
        <div className="border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <Breadcrumb />
          <div className="mt-4 flex max-sm:ml-4 max-sm:mb-4 sm:mt-0 sm:mr-4"></div>
        </div>
        <div className="flex flex-col mt-6 px-4 sm:px-6 lg:px-8">
          <form
            className="flex w-full py-4"
            onSubmit={handleSubmit(handleOnSubmit)}
          >
            <div className="bg-white w-full shadow rounded mx-auto py-8 px-6">
              <div className="flex flex-col justify-between items-center gap-2">
                <div className="flex w-full gap-2">
                  <SelectControl
                    control={control}
                    name="skill_tag_category"
                    className="cursor-pointer"
                    label={t('pages.skill.skill_tag_category')}
                    options={[
                      ...[{ value: 0, label: `${t('common.label.choose')}` }],
                      ...(skillTagCategories as SkillTagCategory[]).map(v => ({
                        value: v.id,
                        label: v.name,
                      })),
                    ]}
                    value={skillTagCategory}
                    onHandleChange={e =>
                      setSkillTagCategory(Number(e.target.value))
                    }
                  />

                  <SelectControl
                    control={control}
                    name="skill_category"
                    label={t('pages.skill.skill_category')}
                    className="cursor-pointer"
                    options={[
                      ...[{ value: 0, label: `${t('common.label.choose')}` }],
                      ...(skillCategories as SkillCategory[]).map(v => ({
                        value: v.id,
                        label: v.name,
                      })),
                    ]}
                    value={skillCategory}
                    onHandleChange={e =>
                      setSkillCategory(Number(e.target.value))
                    }
                  />
                </div>

                <div className="flex w-full gap-2">
                  <SelectControl
                    control={control}
                    name="skill_id"
                    label={t('pages.skill.skill')}
                    className="cursor-pointer"
                    options={[
                      ...[{ value: '', label: `${t('common.label.choose')}` }],
                      ...(skills as Skill[]).map(v => ({
                        value: v.id,
                        label: v.name,
                      })),
                    ]}
                    required={true}
                  />

                  <SelectControl
                    control={control}
                    name="skill_level_id"
                    label={t('pages.skill.skill_level')}
                    className="cursor-pointer"
                    options={[
                      ...[{ value: '', label: `${t('common.label.choose')}` }],
                      ...(skillLevels as SkillLevel[]).map(v => ({
                        value: v.id,
                        label: v.name,
                      })),
                    ]}
                    required={true}
                  />

                  <SelectControl
                    control={control}
                    name="skill_experience_id"
                    label={t('pages.skill.skill_experience')}
                    className="cursor-pointer"
                    options={[
                      ...[{ value: '', label: `${t('common.label.choose')}` }],
                      ...(skillExperiences as SkillExperience[]).map(v => ({
                        value: v.id,
                        label: v.label,
                      })),
                    ]}
                    required={true}
                  />
                </div>

                <div className="flex w-full">
                  <Button
                    type="submit"
                    className="flex justify-center items-center gap-x-2 w-full !py-3 mt-3"
                    variant="default"
                  >
                    {t('common.button.add')}
                    <PlusCircleIcon className="w-5 h-5 text-white" />
                  </Button>
                </div>
              </div>
            </div>
          </form>
          <div className="grid grid-cols-3 gap-2 w-full">
            {(employeeSkill as SkillEmployee[]) &&
              (employeeSkill as SkillEmployee[]).map((value, key) => (
                <div
                  key={key}
                  className="flex flex-col items-center justify-center relative gap-1 w-full p-2 py-2 border-[1px] border-solid rounded-sm text-[#34495e]"
                >
                  <div className="absolute translate-x-[-50%] translate-y-[-50%] top-[50%] right-0 z-10 rounded-[50%] w-12 h-12 bg-slate-200 flex justify-center items-center">
                    <TrashIcon
                      onClick={() => onHandleDelete(value.id)}
                      className="text-red-600 h-5 w-5 cursor-pointer"
                    />
                  </div>
                  <div className="w-full pb-1 bg-indigo-600 absolute left-0 top-0 rounded-b-sm"></div>
                  {value.is_main && (
                    <div className="w-8 h-8  absolute top-[50%] right-[0] translate-x-[-50%] translate-y-[-50%] cursor-pointer">
                      <StarIcon className="w-full h-full text-[#f1c40f]" />
                    </div>
                  )}
                  <div className="flex flex-col w-full overflow-hidden whitespace-nowrap">
                    <h1 className="text-3xl font-bold">{value.skill?.name}</h1>
                  </div>
                  <div className="flex flex-col w-full overflow-hidden">
                    <p>{value.skill_level?.name}</p>
                  </div>
                  <div className="flex w-full">
                    <span className="bg-indigo-500 rounded-md px-2 py-1 text-sm justify-start text-white font-medium">
                      {value.skill_experience?.label}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </main>
    </>
  )
}
