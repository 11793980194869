import { ListParams, ListResponse } from 'app/types/common'
import { CreateEvaluationDetail, EvaluationWithTerm } from 'app/types/evaluation'
import { MyListTerm, Term, TermOfEvaluation } from 'app/types/term'
import axiosClient from './api'

export const evaluationApi = {
  getListEvaluation: async (
    payload?: ListParams
  ): Promise<ListResponse<EvaluationWithTerm>> => {
    const response = await axiosClient.get('/evaluations', {
      params: payload,
    })
    return response.data
  },

  getListTermOfEvaluation: async (): Promise<ListResponse<TermOfEvaluation>> => {
    const response = await axiosClient.get('/term-of-evaluation')
    return response.data
  },

  getEvaluation: async (id: string): Promise<EvaluationWithTerm> => {
    const response = await axiosClient.get('/evaluations/' + id)
    return response.data
  },

  createEvaluation:  async (data: CreateEvaluationDetail | EvaluationWithTerm) => {
    const response = await axiosClient.post('/evaluations', data)
    return response.data
  },

  getListTermResult: async (
    payload: ListParams,
  ): Promise<ListResponse<MyListTerm>> => {
    const response = await axiosClient.get('/my-detail-result', { params: payload })
    return response.data
  },

  getMyDetailResult: async (id: string): Promise<Term> => {
    const response = await axiosClient.get('/my-detail-result/' + id)
    return response.data
  },
}
