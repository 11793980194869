import { Table, TableBody, TableBodyContent, TableBodyTr, TableHeader, TableHeaderContent } from 'app/components/Table'
import { EVALUATION_TYPE_COMPANY, QUESTION_TYPE } from 'app/constants/global'
import { Evaluation } from 'app/types/evaluation'
import { EvaluationDetail } from 'app/types/evaluationDetail'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

interface DetailResultTableProps {
  companyRate: number
  selfRate: number
  averageCompany: number
  averageSelf: number
  evaluations?: Evaluation[]
  type: string
}

export default function SelfCompanyResultTable(props: DetailResultTableProps) {
  const { t } = useTranslation()
  const { companyRate, selfRate, averageCompany, averageSelf, evaluations, type } = props

  function ShowQuestion(props: { evaluationDetail?: EvaluationDetail[] }) {
    const { evaluationDetail } = props

    if (!evaluationDetail) {
      return <></>
    }

    return (
      <Fragment>
        {evaluationDetail.map((evaluationDetail, key) => {
          const termDetailQuestion = evaluationDetail.term_detail_question
          const term_detail = termDetailQuestion.term_detail
          return (
            <TableBodyTr key={key}>
              <TableBodyContent className='w-1/8'>{term_detail?.criteria_name}</TableBodyContent>
              <TableBodyContent className='w-1/3'>
                <h2>{termDetailQuestion.question}</h2>
              </TableBodyContent>
              <TableBodyContent>
                {termDetailQuestion.max_score > 0 && evaluationDetail.score}
              </TableBodyContent>
              <TableBodyContent>{evaluationDetail.comment}</TableBodyContent>
            </TableBodyTr>
          )
        })}
      </Fragment>
    )
  }

  function GetRate(props: { type: string; rate: number }) {
    const { type, rate } = props

    return (
      <h2>
        {type} ({rate}%)
      </h2>
    )
  }

  return (
    <>
      <div className="evaluation-type flex flex-col w-full border-b-solid my-10">
        <div className="flex ">
          {
            <GetRate
              rate={type === EVALUATION_TYPE_COMPANY ? companyRate : selfRate}
              type={type}
            />
          }

          <div className="ml-10">
            <h2 className="font-bold">
              {type === EVALUATION_TYPE_COMPANY
                ? averageCompany.toFixed(2)
                : averageSelf.toFixed(2)}
            </h2>
          </div>
        </div>

        <div className="relative flex flex-col mt-2 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <Table>
            <TableHeader>
              <TableHeaderContent title={t('common.label.criteria_name')} />
              <TableHeaderContent title={t('common.label.content')} />
              <TableHeaderContent title={t('common.label.score')} />
              <TableHeaderContent title={t('common.label.comment')} />
            </TableHeader>
            <TableBody>
              {evaluations
                ?.filter(
                  evaluation =>
                    QUESTION_TYPE[evaluation.evaluation_type_id] === type &&
                    evaluation.evaluation_details,
                )
                .map(evaluation => {
                  const evaluationDetail = evaluation.evaluation_details
                  return (
                    <Fragment key={evaluation.id}>
                      {/* question have mark */}
                      <ShowQuestion
                        evaluationDetail={evaluationDetail?.filter(
                          evaluationDetail =>
                            evaluationDetail.term_detail_question.max_score > 0,
                        )}
                      />

                      {/* question not mark */}
                      <ShowQuestion
                        evaluationDetail={evaluationDetail?.filter(
                          evaluationDetail =>
                            evaluationDetail.term_detail_question.max_score === 0,
                        )}
                      />

                      {/* data not found */}
                      {evaluationDetail?.length === 0 && (
                        <TableBodyTr key={evaluationDetail?.length}>
                          <TableBodyContent className="bg-white">
                            <p className='p-2 italic font-medium'>{t('common.label.no_data')}</p>
                          </TableBodyContent>
                        </TableBodyTr>
                      )}
                    </Fragment>
                  )
                })}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  )
}