import { useTranslation } from 'react-i18next'

export default function HeaderProfileEmployee(props: {
  email: string
  phone: string
  full_name: string
  id: string
  birthday: string
  avatar: string
}) {
  const { email, phone, full_name, id, birthday, avatar } = props
  const { t } = useTranslation()
  const avatarDefault =
    'https://ca.slack-edge.com/T02982DPX-U03NH9JBUR3-bc92bd617148-512'
  function TextProfile(props: { name: string; value: string }) {
    const { value, name } = props
    return (
      <div className="flex gap-2">
        <span>{name}: </span>
        <h2 className=" mb-2 text-md font-medium text-gray-900 dark:text-gray-300 uppercase">
          {value}
        </h2>
      </div>
    )
  }

  return (
    <div className="profile flex flex-col">
      <div className="rounded-3xl overflow-hidden shadow-md my-3 px-10 py-1">
        <div className="flex w-full justify-around items-center gap-2">
          {/* image profile */}
          <div className="w-[300px] h-[200px] flex items-center">
            <img
              src={avatar ?? avatarDefault}
              className="rounded-full w-full h-full border-separate border-gray-300 border-2 block"
              alt="avatar"
            />
          </div>
          {/* info */}
          <div className="flex flex-col w-full justify-start">
            <TextProfile name={t('common.label.full_name')} value={full_name} />
            <TextProfile name={t('common.label.id')} value={id} />
            <TextProfile name={t('common.label.phone_number')} value={phone} />
            <TextProfile name={t('common.label.email')} value={email} />
            <TextProfile name={t('common.label.birthday')} value={birthday} />
          </div>
          {/* logo */}
          <div className="flex">
            <img
              src="https://kozocom.vn/assets/img/main-logo.png"
              alt="logo-kozocom"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
