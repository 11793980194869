import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals'
// Initialize languages
import './locales/i18n'
import './index.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { LoadingProvider } from 'app/providers/LoadingProvider'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const container = document.getElementById('root')!;
const root = createRoot(container);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 200,
      cacheTime: Infinity,
      retry: true,
      initialDataUpdatedAt: 200,
      refetchOnWindowFocus: false,
    },
    mutations: {
      cacheTime: Infinity,
    },
  },
})

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <LoadingProvider>
              <App />
              <ToastContainer autoClose={1000} hideProgressBar={true} />
            </LoadingProvider>
          </Provider>
        </QueryClientProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
