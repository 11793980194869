import { Breadcrumb } from "app/components/BreadCrumb";
import { Button } from "app/components/Button";
import { SelectControl } from "app/components/FormControl/SelectControl";
import { Table, TableBody, TableBodyContent, TableBodyTr, TableHeader, TableHeaderContent } from "app/components/Table";
import { ROUTES } from "app/config/routes";
import { SELECT_MONTH_OPTION } from "app/constants/global";
import useMonth from "hooks/useMonth";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function OverTimeListPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()


  // column table
  const columnTitle = [
    t('common.label.stt'),
    t('label.over_time.over_time_date'),
    t('common.label.status'),
    t('common.label.request_date'),
    t('common.label.time'),
    t('label.over_time.reason'),
  ]

  const { listYear, month, year, setMonth, setYear, subMonth, plusMonth } = useMonth();

  return (
    <>
      <Helmet>
        <title>{t('pages.evaluation.title')}</title>
      </Helmet>

      <main className="flex-1">
        <div className="border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <Breadcrumb />
        </div>
        <div className="mt-6 px-4 sm:px-6 lg:px-8">
          <div className="mt-8 flex flex-col content-center">
            <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg my-5">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="min-w-full py-2 align-middle md:px-6 lg:px-8 flex flex-row justify-between items-center">
                  <h1 className="text-lg font-bold">
                    {t('label.over_time.name_list')}
                  </h1>
                  <div className="relative px-10">
                    <Button
                      variant="default"
                      className="py-2.5 flex items-center"
                      type="button"
                      onClick={() => navigate(ROUTES.OVER_TIME.CREATE)}
                    >
                      {t('label.leave.new_request')}
                    </Button>
                  </div>
                </div>
                <div className="py-2 align-middle md:px-6 lg:px-10 lg:py-10 flex flex-row items-center bg-gray-100 mx-10">
                  <h1 className="text-ml w-1/6">
                    {t('label.leave.month_choice')}
                  </h1>
                  <div className="flex justify-start w-1/2">
                    <Button
                      onClick={() => subMonth()}
                    >
                      <img
                        src="/assets/icons/back.png"
                        alt="back"
                        className="h-4 w-3"
                      />
                    </Button>
                    <div className="px-2">
                      <SelectControl
                        name={'month'}
                        options={SELECT_MONTH_OPTION}
                        label={""}
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                      />
                    </div>
                    <div className="px-2">
                      <SelectControl
                        name={'year'}
                        options={listYear}
                        label={""}
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                      />
                    </div>
                    <Button
                      onClick={() => plusMonth()}
                    >
                      <img
                        src="/assets/icons/next.png"
                        alt="back"
                        className="h-4 w-3"
                      />
                    </Button>
                  </div>
                  <div className="relative !bottom-0">
                    <Button
                      variant="light"
                      className="py-2.5 flex items-center"
                      type="button"
                    >
                      {t('common.button.show')}
                    </Button>
                  </div>
                </div>
                <div className="min-w-full py-2 md:px-6 lg:px-10 lg:py-10 md:rounded-lg">
                  {/* Table List leave */}
                  <Table>
                    <TableHeader>
                      {columnTitle.map((value, index) => (
                        <TableHeaderContent
                          key={index}
                          title={value}
                          className="text-center"
                        />
                      ))}
                      <TableHeaderContent title="" />
                    </TableHeader>
                    <TableBody>
                      <TableBodyTr>
                        <TableBodyContent className="text-center">1</TableBodyContent>
                        <TableBodyContent className="text-center w-1/6">26/01/2023</TableBodyContent>
                        <TableBodyContent className="text-center w-1/8">Đã duyệt</TableBodyContent>
                        <TableBodyContent className="text-center">04/01/2023</TableBodyContent>
                        <TableBodyContent className="text-center">1Ngày</TableBodyContent>
                        <TableBodyContent className="text-center w-1/4">Nghỉ phép</TableBodyContent>
                        <TableBodyContent></TableBodyContent>
                      </TableBodyTr>
                      <TableBodyTr>
                        <TableBodyContent className="text-center">2</TableBodyContent>
                        <TableBodyContent className="text-center w-1/6">19/01/2023</TableBodyContent>
                        <TableBodyContent className="text-center w-1/8">Đang chờ phê duyệt	</TableBodyContent>
                        <TableBodyContent className="text-center">04/01/2023</TableBodyContent>
                        <TableBodyContent className="text-center">1Ngày</TableBodyContent>
                        <TableBodyContent className="text-center w-1/4">Nghỉ phép</TableBodyContent>
                        <TableBodyContent></TableBodyContent>
                      </TableBodyTr>
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}