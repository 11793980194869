import { TableFooter, TableRow } from '@mui/material'
import {
  Table,
  TableBody,
  TableBodyContent,
  TableBodyTr,
  TableFooterContent,
  TableHeader,
  TableHeaderContent,
} from 'app/components/Table'
import { useTranslation } from 'react-i18next'
import TimeSelect from '../components/TimeSelect'

export default function TimeSheetDetail() {
  const { t } = useTranslation()

  const titles = [
    t('common.label.date'),
    t('common.label.type_date'),
    t('common.label.shift_time'),
    t('common.label.checkin_time'),
    t('common.label.checkout_time'),
    t('common.label.time_work_number'),
    t('common.label.time_out_work_number'),
    t('common.label.ot_time_number'),
    t('common.label.dark_time_number'),
    t('common.label.rest_time'),
    t('common.label.status_attenden'),
  ]
  return (
    <>
      <TimeSelect />
      <div className="flex flex-col w-full px-3 max-md:overflow-auto">
        <Table>
          <TableHeader>
            {titles.map((title, index) => (
              <TableHeaderContent key={index} title={title} />
            ))}
          </TableHeader>
          <TableBody>
            <TableBodyTr>
              <TableBodyContent>
                <span className="underline">31/12</span>(
                <span className="text-blue-600 underline">T7</span>)
              </TableBodyContent>
              <TableBodyContent className="w-24">
                Ngày nghỉ, Ngày lễ
              </TableBodyContent>
              <TableBodyContent className="w-28">10:00～19:00</TableBodyContent>
              <TableBodyContent>14:50</TableBodyContent>
              <TableBodyContent>23:50</TableBodyContent>
              <TableBodyContent>08:00</TableBodyContent>
              <TableBodyContent>04:50</TableBodyContent>
              <TableBodyContent>04:50</TableBodyContent>
              <TableBodyContent>01:50</TableBodyContent>
              <TableBodyContent>01:00</TableBodyContent>
              <TableBodyContent className="w-24 font-bold">
                Thiếu
              </TableBodyContent>
            </TableBodyTr>
            <TableBodyTr>
              <TableBodyContent>
                <span className="underline">01/01</span>(
                <span className="text-red-600 underline">CN</span>)
              </TableBodyContent>
              <TableBodyContent className="w-24">
                Ngày nghỉ, Ngày lễ
              </TableBodyContent>
              <TableBodyContent className="w-28">10:00～19:00</TableBodyContent>
              <TableBodyContent>14:50</TableBodyContent>
              <TableBodyContent>23:50</TableBodyContent>
              <TableBodyContent>08:00</TableBodyContent>
              <TableBodyContent>04:50</TableBodyContent>
              <TableBodyContent>04:50</TableBodyContent>
              <TableBodyContent>01:50</TableBodyContent>
              <TableBodyContent>01:00</TableBodyContent>
              <TableBodyContent className="w-24 text-red-500 font-bold">
                Muộn
              </TableBodyContent>
            </TableBodyTr>
            <TableBodyTr>
              <TableBodyContent>
                <span className="underline">02/01</span>(
                <span className="underline">T2</span>)
              </TableBodyContent>
              <TableBodyContent className="w-24">
                Ngày nghỉ, Ngày lễ
              </TableBodyContent>
              <TableBodyContent className="w-28">10:00～19:00</TableBodyContent>
              <TableBodyContent>14:50</TableBodyContent>
              <TableBodyContent>23:50</TableBodyContent>
              <TableBodyContent>08:00</TableBodyContent>
              <TableBodyContent>04:50</TableBodyContent>
              <TableBodyContent>04:50</TableBodyContent>
              <TableBodyContent>01:50</TableBodyContent>
              <TableBodyContent>01:00</TableBodyContent>
              <TableBodyContent className="w-24 text-red-500 font-bold"></TableBodyContent>
            </TableBodyTr>
          </TableBody>
          <TableFooter className="bg-gray-300 border-gray-400 !font-bold !text-black-500">
            <TableRow className="!font-bold !text-black-500">
              <TableFooterContent colspan={2}>
                {t(`common.label.total`)}
              </TableFooterContent>
              <TableFooterContent colspan={1}></TableFooterContent>
              <TableFooterContent colspan={1}></TableFooterContent>
              <TableFooterContent colspan={1}></TableFooterContent>
              <TableFooterContent colspan={1}>40:12</TableFooterContent>
              <TableFooterContent colspan={1}>40:12</TableFooterContent>
              <TableFooterContent colspan={1}>40:12</TableFooterContent>
              <TableFooterContent colspan={1}>40:12</TableFooterContent>
              <TableFooterContent colspan={1}>40:12</TableFooterContent>
              <TableFooterContent colspan={1}></TableFooterContent>
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </>
  )
}
