import { forwardRef, SelectHTMLAttributes } from 'react'

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: any[]
  label?: string
  error?: any
  name?: string
  onChange?: (e: any) => void
  onHandleChange?: (e: any) => void
  className?: string
  disabled?: boolean
}

export const Select = forwardRef((props: SelectProps, ref) => {
  const {
    options,
    label,
    error,
    name,
    onChange,
    onHandleChange,
    className,
    disabled,
    ...restProps
  } = props

  const handleSelectedOptionChange = (e: any) => {
    const changeEvent = {
      target: {
        name: e.target.name,
        value: e.target.value,
      },
    }
    onChange?.(changeEvent)
    onHandleChange?.(e)
  }

  return (
    <>
      <div className="w-full">
        <div className="flex justify-between">
          <label className="text-sm font-medium text-gray-700" htmlFor={name}>
            {label}
            {restProps.required && <span className="text-[#e74c3c] text-md"> *</span>}
            {/* toggle loading */}
            {!options && (
              <span className="ml-2 flex">
                <span className="animate-spin rounded-full h-4 w-4 border-b border-r border-gray-600"></span>
              </span>
            )}
          </label>
        </div>
        <div className={`mt-1 ${error ? 'is-invalid' : ''}`}>
          <select
            disabled={disabled}
            className={`relative disabled:bg-[#e5e7eb59] disabled:border-[#b0b4bc] disabled:font-[400] mt-1 py-2 px-3 block w-full rounded-md border-solid border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${className}`}
            id={name}
            {...restProps}
            onChange={handleSelectedOptionChange}
          >
            {options &&
              options.map(
                (item: { value: string; label: string }, index: number) => (
                  <option value={item.value} key={index}>
                    {item.label}
                  </option>
                ),
              )}
          </select>
        </div>
        {error && <p className="feedback text-left">{error.message}</p>}
      </div>
    </>
  )
})
