import { useState } from 'react'
import { getYearOption } from 'utils/helper'

const useMonth = () => {
  const fullDate = new Date()
  const [month, setMonth] = useState(fullDate.getMonth() + 1)
  const [year, setYear] = useState(fullDate.getFullYear())
  const listYear = getYearOption()
  const plusMonth = () => {
    if (month === 12) {
      if (year.toString() === listYear[getYearOption().length - 1].label) return
      setMonth(1)
      setYear(year + 1)
    } else {
      setMonth(month + 1)
    }
  }

  const subMonth = () => {
    if (month === 1) {
      if (year.toString() === listYear[0].label) return
      setMonth(12)
      setYear(year - 1)
    } else {
      setMonth(month - 1)
    }
  }
  return {listYear, month, year, setMonth, setYear, subMonth, plusMonth }
}

export default useMonth
