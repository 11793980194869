import { API_URL } from 'app/config'
import { httpStatus } from 'app/constants/common'
import axios from 'axios'
import { toast } from 'react-toastify'
import { getAccessToken } from 'utils/auth'


const axiosClient = axios.create({
  baseURL: `${API_URL}/`,
  withCredentials: true,
  headers: {
    'Content-type': 'application/json; charset=utf-8',
  },
})

axiosClient.interceptors.request.use(
  async function (config: any) {
    config.headers['Authorization'] = `Bearer ${await getAccessToken()}`
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

axiosClient.interceptors.response.use(
  function (response) {
    return response?.data ?? {}
  },
  function (error) {
    const errorResponse = error.response.data
    if (error.response.status === httpStatus.UNAUTHORIZED) {
      setHeader('')
    }
    if (error.response.status === httpStatus.NOT_FOUND) {
      return window.location.href = '/*'
    }
    if (
      errorResponse?.meta.error_message !== null &&
      typeof errorResponse?.meta.error_message === 'string'
    ) {
      const message = errorResponse.meta.error_message
      toast.error(message)
    }
    return Promise.reject(errorResponse)
  },
)

export const setHeader = (authToken: any) => {
  axiosClient.defaults.headers.common.Authorization = `${authToken.token_type} ${authToken.access_token}`
  window.localStorage.setItem('auth.token', JSON.stringify(authToken))
}

if (window.localStorage.getItem('auth.token')) {
  const authToken = JSON.parse(window.localStorage.getItem('auth.token') || '')
  setHeader(authToken)
}

export default axiosClient
