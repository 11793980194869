import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { Button } from 'app/components/Button'
import { InputControl } from 'app/components/FormControl'
import { TextareaControl } from 'app/components/FormControl/TextareaControl'
import { EVALUATION_COLLEAGUE_MAX_SCORE, MIN_LENGTH_COLLEAGUE_COMMENT } from 'app/constants/global'
import { EvaluationQuestionDetail } from 'app/types/evaluation'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'


interface ColleagueEvaluationTableProps {
  evaluationList: EvaluationQuestionDetail[]
  setEvaluationList: (value: EvaluationQuestionDetail[]) => void
  totalScore: number
  setTotalScore: (value: number) => void
  evaluationDetail: EvaluationQuestionDetail[]
  hidden: boolean
}

export default function ColleagueEvaluationTable(props: ColleagueEvaluationTableProps) {
  const { t } = useTranslation()
  const { evaluationList, setEvaluationList, totalScore, setTotalScore, hidden } = props
  const [newComment, setNewComment] = useState('')
  const [newScore, setNewScore] = useState('')
  const [newCriteriaCustom, setNEwCriteriaCustom] = useState('')

  const [listInputValidation, setListInputValidation] = useState<number[]>([])

  const onHandleChangeInput = (e: any, index: number) => {
    const { name, value } = e.target

    if (name === t('label.evaluation.comment')) {
      evaluationList[index].comment = value
      // validation
      if (
        value.length < MIN_LENGTH_COLLEAGUE_COMMENT
      ) {
        if (!listInputValidation.includes(index)) {
          setListInputValidation([...listInputValidation, index])
        }
      } else {
        setListInputValidation(
          [...listInputValidation].filter(
            inputValidation => inputValidation !== index,
          ),
        )
      }
    } else if (name === t('label.evaluation.score')) {
      (+value > EVALUATION_COLLEAGUE_MAX_SCORE) && toast.warning(`${t('messages.error.evaluation.unable_score')}`)
      setTotalScore(+((totalScore * evaluationList.length + +value - evaluationList[index].score) / evaluationList.length).toFixed(2) || 0)
      evaluationList[index].score = +value
    } else {
      evaluationList[index].criteria_custom = value
    }
    setEvaluationList(evaluationList)
  }

  const handleDelete = (index: number) => {
    setEvaluationList([...evaluationList].filter((v, i) => i !== index))
    setTotalScore(+((totalScore * evaluationList.length - evaluationList[index].score) / (evaluationList.length - 1)).toFixed(2) || 0)
  }

  const handleSubmitEvaluation = () => {
    if (Number(newScore) === 0 || newCriteriaCustom === '') return

    if (+newScore > EVALUATION_COLLEAGUE_MAX_SCORE) {
      toast.warning(`${t('messages.error.evaluation.unable_question_score')}`);
    }

    if (
      newComment.length < MIN_LENGTH_COLLEAGUE_COMMENT && !listInputValidation.includes(evaluationList.length)
    ) setListInputValidation([...listInputValidation, evaluationList.length])

    setEvaluationList([
      ...evaluationList,
      {
        comment: newComment,
        score: Number(newScore),
        criteria_custom: newCriteriaCustom,
        max_score: EVALUATION_COLLEAGUE_MAX_SCORE,
        term_detail_question_id: 0
      },
    ])

    setTotalScore((totalScore * (evaluationList.length) + +newScore) / (evaluationList.length + 1) || 0)
    setNewComment('')
    setNEwCriteriaCustom('')
    setNewScore('')
  }

  return (
    <>
      {
        evaluationList.map((value, index) => (
          <div className="relative overflow-hidden md:rounded-lg py-5" key={index}>
            <div className='px-10 py-2 flex w-full items-end'>
              <InputControl
                label={t('common.label.criteria_name')}
                name={t('common.label.criteria_name')}
                className="w-1/2"
                defaultValue={value.criteria_custom}
                onChange={e => onHandleChangeInput(e, index)}
                disabled={!hidden}
              />
              <InputControl
                label={`${t('label.evaluation.score')} (Tối đa: ${EVALUATION_COLLEAGUE_MAX_SCORE})`}
                name={t('label.evaluation.score')}
                onChange={e => onHandleChangeInput(e, index)}
                defaultValue={value.score}
                type="number"
                className="w-1/4"
                disabled={!hidden}
              />
              {
                hidden && <Button
                  type="button"
                  onClick={() => handleDelete(index)}
                  variant="default"
                  className="flex justify-center items-center py-3"
                >
                  <XCircleIcon className="h-5 w-5" aria-hidden="true" />
                  {t('common.label.delete')}
                </Button>
              }
            </div>
            <div className='px-10 py-2'>
              <TextareaControl
                label={t('label.evaluation.comment_title')}
                name={t('label.evaluation.comment')}
                rows={6}
                onChange={e => onHandleChangeInput(e, index)}
                defaultValue={value.comment}
                className={`${listInputValidation.includes(index) &&
                  'border border-red-700'
                  }`}
                disabled={!hidden}
              />
            </div>
            {listInputValidation.includes(index) && (
              <p className="feedback text-red-700 px-10">{`${t(
                'validation.min',
                {
                  field: t('label.evaluation.comment'),
                  min: MIN_LENGTH_COLLEAGUE_COMMENT,
                },
              )}`}</p>
            )}
          </div>
        ))}

      {
        hidden && <div className="relative overflow-hidden md:rounded-lg py-5">
          <div className='px-10 py-2 flex w-full items-end'>
            <InputControl
              label={t('common.label.criteria_name')}
              name={t('common.label.criteria_name')}
              className="w-1/2"
              value={newCriteriaCustom}
              onChange={e => setNEwCriteriaCustom(e.target.value)}
            />
            <InputControl
              label={`${t('label.evaluation.score')} (Tối đa: ${EVALUATION_COLLEAGUE_MAX_SCORE})`}
              name={t('label.evaluation.score')}
              onChange={e => setNewScore(e.target.value)}
              value={newScore}
              type="number"
              className="w-1/4"
            />
            <Button
              type="button"
              onClick={handleSubmitEvaluation}
              variant="default"
              className="flex justify-center items-center py-3"
            >
              <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
              {t('common.label.add')}
            </Button>
          </div>
          <div className='px-10 py-2'>
            <TextareaControl
              label={t('label.evaluation.comment_title')}
              name={t('label.evaluation.comment')}
              rows={6}
              onChange={e => setNewComment(e.target.value)}
              value={newComment}
            />
          </div>
        </div>
      }
    </>
  )
}
