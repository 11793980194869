import { Button } from 'app/components/Button'
import { SelectControl } from 'app/components/FormControl/SelectControl'
import {
  Table,
  TableBody,
  TableBodyContent,
  TableBodyTr
} from 'app/components/Table'
import { SELECT_MONTH_OPTION } from 'app/constants/global'
import useMonth from 'hooks/useMonth'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ShowStatistic from './ShowStatistic'

export default function TimeSelect() {
  const { t } = useTranslation()
  const [showStatistic, setShowStatistic] = useState(false)
  const { listYear, month, year, setMonth, setYear, subMonth, plusMonth } =
    useMonth()
  const handleShowStatistic = () => {
    setShowStatistic(!showStatistic)
  }

  return (
    <>
      <div className="px-3 relative w-auto pointer-events-none p-4">
        <div className="border-none shadow-md relative flex flex-col pointer-events-auto bg-white bg-clip-padding rounded-md outline-none">
          <div className="relative">
            <div className="pt-4 bg-[#f7f7f7] w-full mx-auto pb-4 px-6 flex justify-between gap-3">
              <div className="flex flex-col w-full">
                <div className="flex flex-col max-sm:w-full max-sm:overflow-auto">
                  <Table>
                    <TableBody>
                      <TableBodyTr className="relative flex justify-end w-full 2xl:justify-around max-sm:flex-col">
                        <TableBodyContent>
                          <p className="block text-sm font-medium text-gray-700 flex">
                            {t(`label.attenden.month`)}
                          </p>
                        </TableBodyContent>
                        <TableBodyContent>
                          <div className="flex justify-start w-full max-sm:flex-col">
                            <Button
                              className="max-sm:hidden mt-[5px]"
                              onClick={() => subMonth()}
                            >
                              <img
                                src="/assets/icons/back.png"
                                alt="back"
                                className="h-4 w-3"
                              />
                            </Button>
                            <div className="px-2">
                              <SelectControl
                                className="max-sm:p-[5px]"
                                name={'month'}
                                options={SELECT_MONTH_OPTION}
                                label={''}
                                value={month}
                                onChange={e => setMonth(e.target.value)}
                              />
                            </div>
                            <div className="px-2">
                              <SelectControl
                                className="max-sm:p-[5px]"
                                name={'year'}
                                options={listYear}
                                label={''}
                                value={year}
                                onChange={e => setYear(e.target.value)}
                              />
                            </div>
                            <Button
                              className="max-sm:hidden mt-[5px]"
                              onClick={() => plusMonth()}
                            >
                              <img
                                src="/assets/icons/next.png"
                                alt="back"
                                className="h-4 w-3"
                              />
                            </Button>
                          </div>
                        </TableBodyContent>
                      </TableBodyTr>
                    </TableBody>
                  </Table>
                  <div className="flex justify-between 2xl:justify-start px-3 max-sm:flex-col-reverse mt-[20px]">
                    <Button
                      type="submit"
                      variant="default"
                      onClick={() => handleShowStatistic()}
                      className="!ml-0  py-1 px-4 h-8 mr-2"
                    >
                      {t(`common.button.show_data`)}
                    </Button>
                    <div className="flex max-md:flex-col">
                      <Button
                        variant="default"
                        className="max-md:mb-[10px] !max-sm:text-[13px] py-1 px-4 h-8  mr-2"
                      >
                        {t(`common.button.show`)}
                      </Button>
                      <Button
                        variant="default"
                        className="max-md:mb-[10px] py-1 px-4 h-8 mr-2"
                      >
                        {t(`common.label.download`)}
                      </Button>
                      <div className="flex flex-row justify-between text-xs mt-2 max-sm:justify-end">
                        <div className="flex items-center mb-4">
                          <input
                            type="radio"
                            name="type_download"
                            defaultChecked={true}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                          />
                          <label htmlFor="pdf" className="ml-2 mr-2 text-xs">
                            {t(`common.label.pdf`)}
                          </label>
                        </div>
                        <div className="flex items-center mb-4">
                          <input
                            type="radio"
                            name="type_download"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                          />
                          <label htmlFor="pdf" className="ml-2 mr-1">
                            {t(`common.label.csv`)}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full px-3 py-4">
        {showStatistic ? <ShowStatistic></ShowStatistic> : ''}
      </div>
    </>
  )
}
