import { ListParams } from 'app/types/common'
import axiosClient from './api'

export const authApi = {
  login: async (payload: ListParams) => {
    const response = await axiosClient.post('/login', payload)
    return response.data
  },
  logout: async () => {
    return await axiosClient.post('/logout')
  },
  sendMailResetPassword: async (data: ListParams) => {
    const response = await axiosClient.post('reset-password/email', data)
    return response.data
  },
  resetPassword: async (data: ListParams) => {
    const response = await axiosClient.post('reset-password/reset', data)
    return response.data
  },
}
