
import { Employee } from 'app/types/employee'
import axiosClient from '.'

export const profileApi = {
  getEmployee: async (): Promise<Employee> => {
    const response = await axiosClient.get('/employee-info')
    return response.data
  },


  update: async (id: string, data: any) => {
    const response = await axiosClient.put('/employee-info', data)
    return response.data
  },
}
