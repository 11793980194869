import { useQuery } from '@tanstack/react-query'
import { Breadcrumb } from 'app/components/BreadCrumb'
import { useLoading } from 'app/context/loading'
import { evaluationApi } from 'app/services/evaluationApi'
import { Term } from 'app/types/term'
import { TermEmployee } from 'app/types/termEmployee'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  EVALUATION_TYPE_COLLEAGUE,
  EVALUATION_TYPE_COMPANY,
  EVALUATION_TYPE_SELF,
  QUESTION_TYPE,
} from 'app/constants/global'
import { Evaluation } from 'app/types/evaluation'
import ColleagueResultTable from '../components/ColleagueResultTable'
import SelfCompanyResultTable from '../components/SelfCompanyResultTable'


export default function DetailResultPage() {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { hideLoading, showLoading } = useLoading()
  const [term, setTerm] = useState<Term>()
  const [termEmployee, setTermEmployee] = useState<TermEmployee>()

  const [rates, setRates] = useState({
    colleague: 0,
    company: 0,
    self: 0,
  })

  const [averageAll, setAverageALl] = useState({
    average: 0.0,
    averageSelf: 0.0,
    averageCompany: 0.0,
    averageColleague: 0.0,
  })

  const getAverageScore = (type: string, evaluations?: Evaluation[]) => {
    if (!evaluations) return 0

    if (type === EVALUATION_TYPE_COLLEAGUE) {
      return myDetailResult?.colleague_score ?? 0
    }

    const sum =
      evaluations
        ?.map(evaluation => {
          const evaluationDetails = evaluation.evaluation_details
          if (QUESTION_TYPE[evaluation.evaluation_type_id] === type) {
            const sum =
              evaluationDetails
                ?.filter(
                  evaluationDetail =>
                    evaluationDetail.term_detail_question.max_score > 0,
                )
                ?.map(({ score }) => score) || []

            return sum.reduce((a, b) => a + b, 0) || 0
          }

          return 0
        })
        .filter(value => value > 0) || []

    return sum?.reduce((a, b) => a + b, 0) / sum.length || 0
  }

  useEffect(() => {
    if (!termEmployee) return

    const sumCompany = getAverageScore(
      EVALUATION_TYPE_COMPANY,
      termEmployee.evaluations,
    )

    const sumSelf = getAverageScore(
      EVALUATION_TYPE_SELF,
      termEmployee.evaluations,
    )

    const sumColleague = getAverageScore(
      EVALUATION_TYPE_COLLEAGUE,
      termEmployee.evaluations,
    )

    setAverageALl({
      ...averageAll,
      averageSelf: sumSelf,
      averageColleague: sumColleague,
      averageCompany: sumCompany,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termEmployee])

  useEffect(() => {
    const sumAverageCompany = averageAll.averageCompany * (rates.company / 100)
    const sumAverageSelf = averageAll.averageSelf * (rates.self / 100)
    const sumAverageColleague =
      averageAll.averageColleague * (rates.colleague / 100)

    setAverageALl({
      ...averageAll,
      average: sumAverageCompany + sumAverageSelf + sumAverageColleague,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    averageAll.averageCompany,
    averageAll.averageSelf,
    averageAll.averageColleague,
  ])

  useEffect(() => {
    showLoading()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // fetch data my detail result
  const {
    data: myDetailResult,
    isLoading
  } = useQuery(['myDetailResult', id], () => evaluationApi.getMyDetailResult(`${id}`), {
    onSuccess: evaluation => {
      setTerm(evaluation)
      if (evaluation.term_employees) setTermEmployee(evaluation.term_employees)
      setRates({
        colleague: evaluation.colleague_rate,
        company: evaluation.company_rate,
        self: evaluation.self_rate,
      })
      hideLoading()
    },
  })

  if (isLoading || !myDetailResult) return ''

  if (!term || !termEmployee) return <></>

  return (
    <>
      <Helmet>
        <title>{t('pages.evaluation.title')}</title>
      </Helmet>

      <main className="flex-1">
        <div className="border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <Breadcrumb />
        </div>
        <div className="mt-6 px-4 sm:px-6 lg:px-8">
          <div className="mt-8 flex flex-col content-center">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg my-5">
                  <div className="min-w-full py-2 align-middle md:px-6 lg:px-8 flex flex-row justify-between items-center">
                    <h1 className='text-lg font-bold'>{myDetailResult.name.toUpperCase()}</h1>
                  </div>
                </div>
                <div className="min-w-full py-2 md:px-6 lg:px-8 flex flex-row flex-start">
                  <h1 className='text-lg font-bold w-1/5'>{t('label.my_detail_result.total_score')}</h1>
                  <h1 className='text-lg pl-5'>{averageAll.average.toFixed(2)}</h1>
                </div>

                {/* table self or company */}
                {[EVALUATION_TYPE_COMPANY, EVALUATION_TYPE_SELF].map(value => (
                  <SelfCompanyResultTable
                    key={value}
                    averageCompany={averageAll.averageCompany}
                    averageSelf={averageAll.averageSelf}
                    companyRate={rates.company}
                    selfRate={rates.self}
                    type={value}
                    evaluations={termEmployee.evaluations}
                  />
                ))}

                {/* table colleague */}
                <ColleagueResultTable
                  averageColleague={averageAll.averageColleague}
                  colleagueRate={rates.colleague}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}