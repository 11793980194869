import { ROUTES } from 'app/config/routes'
import EvaluationListPage from 'app/containers/Evaluation/pages'
import EvaluationDetail from 'app/containers/Evaluation/pages/EvaluationDetail'
import ForgetPasswordPage from 'app/containers/ForgetPassword'
import ResetPassword from 'app/containers/ForgetPassword/ResetPassword'
import { Home } from 'app/containers/Home/Loadable'
import LeaveDetailPage from 'app/containers/Leave/pages/LeaveDetail'
import LeaveListPage from 'app/containers/Leave/pages/LeaveList'
import Login from 'app/containers/Login'
import MyEvaluationResultPage from 'app/containers/MyEvaluationResult/pages'
import DetailResultPage from 'app/containers/MyEvaluationResult/pages/DetailResult'
import { NotFoundPage } from 'app/containers/NotFoundPage/Loadable'
import OverTimeListPage from 'app/containers/OverTime/pages'
import OverTimeDetailPage from 'app/containers/OverTime/pages/OverTimeDetail'
import ChangePassword from 'app/containers/Password'
import ViewProfile from 'app/containers/Profile'
import SkillPage from 'app/containers/Skill'
import TimeSheet from 'app/containers/TimeSheet'
import { AuthLayout } from 'app/layouts/Auth'
import { AuthGuardLayout } from 'app/layouts/AuthGuardLayout'
import CommonLayout from 'app/layouts/CommonLayout'
import { DefaultLayout } from 'app/layouts/DefaultLayout'
import { RedirectIfAuthenticated } from 'app/layouts/RedirectIfAuthenticated'

const routes = [
  // Authenticate
  {
    path: ROUTES.AUTH.INDEX,
    auth: RedirectIfAuthenticated,
    element: AuthLayout,
    childrens: [
      {
        path: ROUTES.AUTH.LOGIN,
        element: Login,
      },
      // Forget Password pages routes
      {
        path: ROUTES.AUTH.FORGET_PASSWORD.INDEX,
        element: ForgetPasswordPage,
      },
      // Reset Password pages routes
      {
        path: ROUTES.AUTH.FORGET_PASSWORD.RESET,
        element: ResetPassword,
      },
    ],
  },
  // Home pages routes
  {
    path: ROUTES.HOME.INDEX,
    element: DefaultLayout,
    auth: AuthGuardLayout,
    childrens: [
      {
        index: true,
        element: Home,
      },
      {
        path: ROUTES.HOME.EVALUATION.INDEX,
        element: CommonLayout,
        childrens: [
          {
            index: true,
            element: EvaluationListPage,
          },
          {
            path: ROUTES.HOME.EVALUATION.EDIT,
            element: EvaluationDetail,
          },
        ],
      },
      {
        path: ROUTES.HOME.MY_DETAIL_RESULT.INDEX,
        element: CommonLayout,
        childrens: [
          {
            index: true,
            element: MyEvaluationResultPage,
          },
          {
            path: ROUTES.HOME.MY_DETAIL_RESULT.DETAIL,
            element: DetailResultPage,
          },
        ],
      },
    ],
  },

  // profile page routes
  {
    path: ROUTES.PROFILE.INDEX,
    // element: ViewProfile,
    element: DefaultLayout,
    childrens: [
      {
        index: true,
        element: ViewProfile,
      },
    ],
  },

  // Change password
  {
    path: ROUTES.PASSWORD.INDEX,
    element: DefaultLayout,
    childrens: [
      {
        index: true,
        element: ChangePassword,
      },
    ],
  },

  // leave page routes
  {
    path: ROUTES.LEAVE.INDEX,
    element: DefaultLayout,
    childrens: [
      {
        index: true,
        element: LeaveListPage,
      },
      {
        path: ROUTES.LEAVE.CREATE,
        element: LeaveDetailPage,
      },
    ],
  },

  // over time page routes
  {
    path: ROUTES.OVER_TIME.INDEX,
    element: DefaultLayout,
    childrens: [
      {
        index: true,
        element: OverTimeListPage,
      },
      {
        path: ROUTES.OVER_TIME.CREATE,
        element: OverTimeDetailPage,
      },
    ],
  },

  {
    path: '*',
    element: NotFoundPage,
    meta: {
      middleware: [],
    },
  },

  // SheetTime page routes
  {
    path: ROUTES.TIMESHEET.INDEX,
    element: DefaultLayout,
    childrens: [
      {
        index: true,
        element: TimeSheet,
      },
    ],
  },

  // skill page routes
  {
    path: ROUTES.SKILL.INDEX,
    element: DefaultLayout,
    childrens: [
      {
        index: true,
        element: SkillPage,
      },
    ],
  },
]

export { routes }

