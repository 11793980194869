import { PencilSquareIcon } from '@heroicons/react/24/solid'
import { useMutation, useQueries } from '@tanstack/react-query'
import { Breadcrumb } from 'app/components/BreadCrumb'
import { Button } from 'app/components/Button'
import { ROUTES } from 'app/config/routes'
import {
  EVALUATION_TYPE,
  EVALUATION_TYPE_VIETNAMESE,
  MIN_LENGTH_COLLEAGUE_COMMENT,
  MIN_LENGTH_COMMENT_EVALUATION,
  QUESTION_TYPE,
} from 'app/constants/global'
import { useLoading } from 'app/context/loading'
import { evaluationApi } from 'app/services/evaluationApi'
import { termApi } from 'app/services/termApi'
import {
  CreateEvaluationDetail,
  EvaluationQuestionDetail,
  EvaluationWithTerm,
} from 'app/types/evaluation'
import { isEmpty } from 'lodash'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { toast } from 'react-toastify'
import ColleagueEvaluationTable from '../components/ColleagueEvaluationTable'
import EvaluationDetailTable from '../components/EvaluationDetailTable'
import InformationRow from '../components/InformationRow'

export default function EvaluationDetail() {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const [params, setParams] = useState({})
  const [totalScore, setTotalScore] = useState(0)
  const [evaluationList, setEvaluationList] = useState<EvaluationQuestionDetail[]>([])
  const { showLoading, hideLoading } = useLoading()
  const [evaluation, setEvaluation] = useState<EvaluationWithTerm>()
  const [ended, setEnded] = useState(true)
  const [evaluationType, setEvaluationType] = useState('')
  const navigate = useNavigate()

  const [
    { data: evaluationDetail },
    { data: termDetailList }
  ] = useQueries({
    queries: [
      {
        queryKey: ['evaluationDetail', id],
        queryFn: () => evaluationApi.getEvaluation(`${id}`),
        onSuccess: (e: EvaluationWithTerm) => {
          setParams({
            job_title_id: e.job_title_id,
            term_id: e.term_id,
          })
          setEvaluation(e)
          setEvaluationList(e.evaluation_detail)
          setTotalScore(e.total_score)
          setEnded(new Date(e.takeout_end_time) >= new Date())
          setEvaluationType(e.evaluation_type)
        },
      },
      {
        queryKey: ['termOfEvaluation', params],
        queryFn: () => termApi.getListTermDetail(params),
        enabled: (
          !isEmpty(params) 
          && evaluationType.toUpperCase() !== QUESTION_TYPE[EVALUATION_TYPE.EVALUATION_TYPE_COLLEAGUE]
        ) ? true : false,
      },
    ],
  })

  const mutation = useMutation({
    mutationFn: (data: EvaluationWithTerm | CreateEvaluationDetail) => {
      return evaluationApi.createEvaluation(data)
    },
  })

  const handleOnSubmit = () => {
    let newEvaluations: EvaluationQuestionDetail[] = []

    Object.values(evaluationList).forEach(
      (value: EvaluationQuestionDetail, index) => {
        newEvaluations = [...newEvaluations, value]
      },
    )

    // validate
    const validations = newEvaluations.map(
      value =>
        evaluationDetail?.evaluation_type_id !== EVALUATION_TYPE.EVALUATION_TYPE_COLLEAGUE
          ?
          (value.max_score > 0 && value.comment.length < MIN_LENGTH_COMMENT_EVALUATION)
          :
          value.comment.length < MIN_LENGTH_COLLEAGUE_COMMENT
    )

    if (validations.filter(value => value).length > 0) return

    const data: CreateEvaluationDetail = {
      id: evaluationDetail?.id || 0,
      evaluation_detail: newEvaluations,
      total_score: totalScore
    }

    if (evaluation)
      if (
        newEvaluations.findIndex(
          (e: EvaluationQuestionDetail) =>
            e.comment === '' ||
            (e.score === 0 && e.max_score !== 0) ||
            e.score > e.max_score,
        ) !== -1
      ) {
        toast.error(`${t('messages.error.evaluation.unable')}`)
      } else {
        showLoading()
        mutation.mutate(data,
          {
            onSuccess: evaluationDetail => {
              hideLoading()
              toast.success(`${evaluationDetail.messages}`)
              navigate(ROUTES.HOME.EVALUATION.INDEX)
            },
            onError: () => {
              hideLoading()
            },
          },
        )
      }
  }

  if (!evaluationDetail) return ''

  return (
    <>
      <Helmet>
        <title>{t('pages.evaluation.title')}</title>
      </Helmet>
      <main className="flex-1">
        <div className="border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <Breadcrumb />
        </div>
        <div className="mt-6 px-4 sm:px-6 lg:px-8">
          <div className="mt-8 flex flex-col content-center">
            <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg my-5 mx-8">
              <div className="min-w-full py-2 align-middle md:px-6 lg:px-8 flex flex-row justify-between items-center border-b">
                <h1 className="text-lg font-bold">
                  {evaluationDetail && EVALUATION_TYPE_VIETNAMESE[evaluationDetail.evaluation_type].toLocaleUpperCase()}
                </h1>
              </div>
              <InformationRow
                title={t('common.label.position')}
                data={evaluationDetail.account_name}
              />
              <InformationRow
                title={t('common.label.full_name')}
                data={evaluationDetail.job_title_name}
              />
              <InformationRow
                title={t('label.evaluation.score')}
                data={totalScore.toString()}
              />
            </div>
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              {evaluationDetail?.evaluation_type_id ===
                EVALUATION_TYPE.EVALUATION_TYPE_COLLEAGUE ? (
                <ColleagueEvaluationTable
                  evaluationList={evaluationList}
                  setEvaluationList={setEvaluationList}
                  totalScore={totalScore}
                  setTotalScore={setTotalScore}
                  evaluationDetail={evaluationDetail?.evaluation_detail}
                  hidden={ended}
                />
              ) : (
                <EvaluationDetailTable
                  termDetail={termDetailList?.data}
                  evaluationList={evaluationList}
                  setEvaluationList={setEvaluationList}
                  totalScore={totalScore}
                  setTotalScore={setTotalScore}
                  evaluationDetail={evaluationDetail?.evaluation_detail}
                />
              )}
            </div>
            <div className="px-6 flex flex-row items-start justify-end py-5">
              {
                (ended)
                && <Button
                  type="button"
                  onClick={() => handleOnSubmit()}
                  variant="default"
                  className="flex justify-center items-center py-3 w-1/8"
                >
                  <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
                  {t('common.label.update')}
                </Button>
              }

            </div>
          </div>
        </div>
      </main>
    </>
  )
}
