/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { HomeIcon } from '@heroicons/react/20/solid'
import { ROUTES } from 'app/config/routes'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

export function Breadcrumb() {
  const { t } = useTranslation()
  const { id } = useParams<{ id?: string }>()

  const pathname = window.location.pathname.split('/').filter(v => v !== '')

  const breadcrumbs = pathname.map((value, key) => {
    const checkItemLast = key === pathname.length - 1
    const locationName = pathname.slice(0, key + 1)
    const isCheck = id && checkItemLast

    return {
      name: isCheck ? id : t(`pages.${locationName.join('.')}.title`),
      href: isCheck ? '#' : '/' + locationName.join('/'),
      current: checkItemLast,
    }
  })

  return (
    <div className="min-w-0 flex-1">
      <ol
        role="list"
        className="flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8 h-14"
      >
        <li className="flex">
          <div className="flex items-center">
            <Link
              to={ROUTES.HOME.INDEX}
              className="text-gray-400 hover:text-gray-500"
            >
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {breadcrumbs.map((breadcrumb: any) => (
          <li key={breadcrumb.name} className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <Link
                to={breadcrumb.href}
                className={`ml-4 text-sm font-medium text-gray-500 hover:text-gray-800 ${
                  breadcrumb.current ? 'text-gray-800' : ''
                }`}
              >
                {breadcrumb.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </div>
  )
}
