import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { useQueries } from '@tanstack/react-query'
import { Breadcrumb } from 'app/components/BreadCrumb'
import { Button } from 'app/components/Button'
import { SelectControl } from 'app/components/FormControl/SelectControl'
import { Pagination } from 'app/components/Pagination'
import {
  Table,
  TableBody,
  TableBodyContent,
  TableBodyTr,
  TableHeader,
  TableHeaderContent,
} from 'app/components/Table'
import { ROUTES } from 'app/config/routes'
import { EVALUATION_TYPE_VIETNAMESE } from 'app/constants/global'
import { evaluationApi } from 'app/services/evaluationApi'
import usePagination from 'hooks/usePagination'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { DateFormat, getOptionSelect } from 'utils/helper'

export default function EvaluationListPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { params, handlePageChange, handleRowsPerPageChange } = usePagination()
  const [, setSearchParams] = useSearchParams()

  // column table
  const columnTitle = [
    t('common.label.stt'),
    t('label.evaluation.name'),
    t('label.evaluation.type'),
    t('label.evaluation.total_score'),
    t('label.evaluation.end_date'),
  ]

  const [
    { data: evaluationList, refetch: refetchEvaluationList },
    { data: termOfEvaluation, refetch: refetchTerm },
  ] = useQueries({
    queries: [
      {
        queryKey: ['evaluationList'],
        queryFn: () => evaluationApi.getListEvaluation(params),
        enabled: false,
        initialData: {
          data: [],
          pagination: {
            current_page: 0,
            total_page: 0,
            total_rows: 0,
          },
        },
      },
      {
        queryKey: ['termOfEvaluation'],
        queryFn: evaluationApi.getListTermOfEvaluation,
        enabled: false,
      },
    ],
  })

  // check search by query parameter
  useEffect(() => {
    refetchEvaluationList()
    refetchTerm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search])

  return (
    <>
      <Helmet>
        <title>{t('pages.evaluation.title')}</title>
      </Helmet>

      <main className="flex-1">
        <div className="border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <Breadcrumb />
        </div>
        <div className="mt-6 px-4 sm:px-6 lg:px-8">
          <div className="mt-8 flex flex-col content-center">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="min-w-full py-2 align-middle md:px-6 lg:px-8 flex flex-row justify-between items-center">
                <h1 className="text-lg font-bold">
                  {t('label.evaluation.name_list')}
                </h1>
                <div className="w-1/3">
                  <SelectControl
                    name={t('label.evaluation.term')}
                    className="py-3"
                    label={t('label.evaluation.term')}
                    options={getOptionSelect(
                      termOfEvaluation?.data,
                      t('label.evaluation.closest'),
                    )}
                    onHandleChange={e =>
                      setSearchParams({ ...params, term: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  {/* Table List Evaluation */}
                  <Table>
                    <TableHeader>
                      {columnTitle.map((value, index) => (
                        <TableHeaderContent
                          key={index}
                          title={value}
                          className="text-center"
                        />
                      ))}
                      <TableHeaderContent title="" />
                    </TableHeader>
                    <TableBody>
                      {evaluationList?.data.map((value, index) => (
                        <TableBodyTr key={index}>
                          <TableBodyContent className="text-center">
                            {index + 1}
                          </TableBodyContent>

                          <TableBodyContent className="w-1/3 text-center">
                            {value.account_name}
                          </TableBodyContent>

                          <TableBodyContent className="text-center">
                            {EVALUATION_TYPE_VIETNAMESE[value.evaluation_type]}
                          </TableBodyContent>

                          <TableBodyContent className="text-center">
                            {value.total_score}
                          </TableBodyContent>

                          <TableBodyContent className="text-center">
                            {value.takeout_end_time ? DateFormat(value.takeout_end_time) : ''}
                          </TableBodyContent>

                          <TableBodyContent className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium flex gap-1 justify-end">
                            <Button
                              type="button"
                              variant="light"
                              className="!p-1 !border-none"
                              onClick={() =>
                                navigate(
                                  ROUTES.HOME.EVALUATION.EDIT.replace(
                                    ':id',
                                    value.id.toString(),
                                  ),
                                )
                              }
                            >
                              <PencilSquareIcon className="h-6 w-6" />
                            </Button>
                          </TableBodyContent>
                        </TableBodyTr>
                      ))}

                      {/* data not found */}
                      {!evaluationList && (
                        <TableBodyTr>
                          <TableBodyContent className="bg-white">
                            <p className='p-2 italic font-medium'>{t('common.label.no_data')}</p>
                          </TableBodyContent>
                        </TableBodyTr>
                      )}
                    </TableBody>
                  </Table>
                  {/* Pagination */}
                  <Pagination
                    onPageChange={handlePageChange}
                    forcePage={
                      Number(params.page) > 0 ? Number(params.page) - 1 : 0
                    }
                    totalRows={evaluationList?.pagination.total_rows || 0}
                    rows={
                      Number(params.rows_per_page)
                        ? Number(params.rows_per_page)
                        : 0
                    }
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
