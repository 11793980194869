import { ButtonHTMLAttributes } from 'react'
import { Link } from 'react-router-dom'

type Variant = 'light' | 'default'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variant
  href?: string
  replace?: boolean
  state?: any
}

const buttonBaseClass = 'block text-center'

const buttonTypeClass: Record<Variant, string> = {
  light:
    'text-sm font-medium leading-none text-center text-indigo-700 px-6 py-4 border rounded-md border-indigo-700 hover:bg-gray-100 transform duration-300 ease-in-out',
  default:
    'text-sm font-medium leading-none text-white text-center px-6 py-4 bg-indigo-700 rounded-md hover:bg-indigo-600 transform duration-300 ease-in-out',
}

export function Button(props: ButtonProps) {
  const {
    href,
    className = '',
    variant,
    replace = false,
    state,
    disabled,
    children,
    onClick,
    ...restProps
  } = props

  const buttonClass = `${buttonBaseClass} ${className} ${
    variant && buttonTypeClass[variant]
  } ${disabled && 'opacity-50 cursor-not-allowed'}`

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(e)
  }

  return href ? (
    <Link to={href} className={buttonClass} replace={replace} state={state}>
      {children}
    </Link>
  ) : (
    <button
      className={buttonClass}
      disabled={disabled}
      onClick={handleClick}
      {...restProps}
    >
      {children}
    </button>
  )
}
