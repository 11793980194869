import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useQuery } from '@tanstack/react-query'
import { Breadcrumb } from 'app/components/BreadCrumb'
import { Button } from 'app/components/Button'
import { Pagination } from 'app/components/Pagination'
import { Table, TableBody, TableBodyContent, TableBodyTr, TableHeader, TableHeaderContent } from 'app/components/Table'
import { ROUTES } from 'app/config/routes'
import { evaluationApi } from 'app/services/evaluationApi'
import usePagination from 'hooks/usePagination'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'


export default function MyEvaluationResultPage() {
  const { t } = useTranslation()
  const { params, handlePageChange, handleRowsPerPageChange } = usePagination()
  const navigate = useNavigate()


  // column table
  const columnTitle = [
    t('common.label.stt'),
    t('label.my_detail_result.name'),
    t('label.my_detail_result.total_score'),
    t('label.my_detail_result.start_date'),
    t('label.my_detail_result.end_date'),
  ]

  // fetch data term have my result
  const {
    data: termResult,
  } = useQuery(['termResult'], () => evaluationApi.getListTermResult(params), {
    initialData: {
      data: [],
      pagination: {
        current_page: 0,
        total_page: 0,
        total_rows: 0,
      },
    },
  })

  return (
    <>
      <Helmet>
        <title>{t('pages.evaluation.title')}</title>
      </Helmet>

      <main className="flex-1">
        <div className="border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <Breadcrumb />
        </div>
        <div className="mt-6 px-4 sm:px-6 lg:px-8">
          <div className="mt-8 flex flex-col content-center">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  {/* Table List Evaluation */}
                  <Table>
                    <TableHeader>
                      {columnTitle.map((value, index) => (
                        <TableHeaderContent key={index} title={value} className='text-center' />
                      ))}
                      <TableHeaderContent title="" />
                    </TableHeader>
                    <TableBody>
                      {
                        termResult.data.map((value, index) => (
                          <TableBodyTr key={index}>
                            <TableBodyContent className='text-center'>{index + 1}</TableBodyContent>

                            <TableBodyContent className='w-1/3 text-center'>{value.name}</TableBodyContent>

                            <TableBodyContent className='text-center'>{value.total_score}</TableBodyContent>

                            <TableBodyContent className='text-center'>{value.takeout_start_time}</TableBodyContent>

                            <TableBodyContent className='text-center'>{value.takeout_end_time}</TableBodyContent>

                            <TableBodyContent className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium flex gap-1 justify-end">
                              <Button
                                type="button"
                                variant="light"
                                className="!p-1 !border-none"
                                onClick={() =>
                                  navigate(ROUTES.HOME.EVALUATION.EDIT.replace(':id', value.id.toString()))
                                }
                              >
                                <DocumentMagnifyingGlassIcon className="h-6 w-6" />
                              </Button>
                            </TableBodyContent>
                          </TableBodyTr>
                        ))
                      }

                      {!termResult &&
                        (
                          <TableBodyTr >
                            <TableBodyContent className="bg-white">
                              <p className='p-2 italic font-medium'>{t('common.label.no_data')}</p>
                            </TableBodyContent>
                          </TableBodyTr>
                        )}
                    </TableBody>
                  </Table>
                  {/* Pagination */}
                  <Pagination
                    onPageChange={handlePageChange}
                    forcePage={
                      Number(params.page) > 0 ? Number(params.page) - 1 : 0
                    }
                    totalRows={termResult?.pagination.total_rows || 0}
                    rows={
                      Number(params.rows_per_page)
                        ? Number(params.rows_per_page)
                        : 0
                    }
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}