import { Breadcrumb } from "app/components/BreadCrumb";
import { SelectControl } from "app/components/FormControl/SelectControl";
import { SELECT_MONTH_OPTION } from "app/constants/global";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { TextareaControl } from "app/components/FormControl/TextareaControl";
import { Button } from "app/components/Button";
import './Calendar.css'
import { InputControl } from "app/components/FormControl";

export default function LeaveDetailPage() {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>{t('pages.evaluation.title')}</title>
      </Helmet>

      <main className="flex-1">
        <div className="border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <Breadcrumb />
        </div>
        <div className="mt-6 px-4 sm:px-6 lg:px-8">
          <div className="mt-8 flex flex-col content-center">
            <div className="relative shadow ring-1 ring-black ring-opacity-5 md:rounded-lg my-5 w-1/2">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="flex justify-start py-5">
                  <SelectControl
                    name={'name'}
                    options={SELECT_MONTH_OPTION}
                    label={t('label.leave.name')}
                    required={true}
                  />
                </div>
                <div className="flex py-5 flex-col w-1/3">
                  <InputControl
                    type="date"
                    label={t('label.leave.leave_date')}
                    name={'date'}
                    className="!py-3"
                    required={true}
                  />
                </div>
                <div className="py-5 ">
                  <TextareaControl
                    name={'name'}
                    label={t('label.leave.reason')}
                    requiredIcon={true}
                    rows={5}
                  />
                </div>
                <div className="flex justify-end py-2">
                  <Button
                    variant="default"
                    className="py-2.5 flex items-center"
                    type="button"
                  >
                    {t('label.leave.request')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}