import common from './items/common.json';
import validation from './items/validation.json';
import pages from './items/pages.json';
import label from './items/label.json';
import messages from './items/messages.json';

const translations = {
  common,
  validation,
  pages,
  label,
  messages
}
export default translations