import { Breadcrumb } from 'app/components/BreadCrumb'
import { useTranslation } from 'react-i18next'
import TimeSheetDetail from './pages/TimeSheetDetail'

export default function TimeSheet() {
  const { t } = useTranslation()
  return (
    <>
      <div className="border-b top-0 border-gray-200 sm:flex sm:items-center sm:justify-between">
        <Breadcrumb />
      </div>
      <div className="relative w-full pointer-events-none p-4 ">
        <div className="border-none shadow-md relative flex flex-col pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="relative">
            <div className="flex py-4">
              <div className="text-sm bg-white w-full mx-auto pb-6 px-6 flex flex-col">
                <div className="px-3 font-bold text-lg font-semibold">
                  {t(`common.label.statistic_table`)} <span>1/2023</span>
                </div>
                <TimeSheetDetail />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
