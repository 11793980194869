import { forwardRef, LegacyRef, TextareaHTMLAttributes } from 'react'

interface FormTextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  name?: string
  error?: any
  requiredIcon?: boolean
  textColor?: 'black' | string
  onChange?: (e: any) => void
}

export const FormTextarea = forwardRef(
  (props: FormTextareaProps, ref: LegacyRef<HTMLTextAreaElement>) => {
    const {
      label,
      name,
      error,
      textColor,
      className,
      requiredIcon,
      onChange,
      ...textareaProps
    } = props

    let classNameOveride = className
    if (className) {
      classNameOveride = className
        .replace(/\s\s+/g, ' ')
        .split(' ')
        .map(classStr => `!${classStr}`)
        .join(' ')
    }

    const handleChange = (e: any) => {
      const changeEvent = {
        target: {
          name: e.target.name,
          value: e.target.value,
        },
      }
      onChange?.(changeEvent)
    }

    return (
      <div className='form-group'>
        <label htmlFor={name} className="block text-sm text-left font-medium text-gray-700">
          {label}
          {requiredIcon && (
            <span className="text-[#e74c3c] text-md"> *</span>
          )}
        </label>
        <div className="mt-1">
          <textarea
            ref={ref}
            name={name}
            id={name}
            onChange={handleChange}
            className={`block w-full pt-2 pl-2 rounded-md border-solid border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${classNameOveride}`}
            {...textareaProps}
          />
        </div>
        {error && <p className="feedback">{error.message}</p>}
      </div>
    )
  },
)
