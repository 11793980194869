import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { Breadcrumb } from 'app/components/BreadCrumb'
import { Button } from 'app/components/Button'
import { InputControl } from 'app/components/FormControl'
import { useLoading } from 'app/context/loading'
import { accountApi } from 'app/services/api/accountApi'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { handleSetError } from 'utils/helper'
import * as yup from 'yup'

export default function ChangePassword() {
  const { t } = useTranslation()
  const { showLoading, hideLoading } = useLoading()

  const initialValues = {
    current_password: '',
    new_password: '',
    new_confirm_password: '',
  }

  const validations = yup.object().shape({
    current_password: yup.string().required(
      t('validation.required', {
        field: t('label.login.password'),
      }),
    ),
    new_password: yup.string().required(
      t('validation.required', {
        field: t('label.password.new_password'),
      }),
    ),
    new_confirm_password: yup
      .string()
      .required(
        t('validation.required', {
          field: t('label.password.confirm_password'),
        }),
      )
      .oneOf(
        [yup.ref('new_password'), null],
        t('validation.password.new_password_not_match'),
      ),
  })

  const { handleSubmit, control, setError } = useForm({
    resolver: yupResolver(validations),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
  })
  const mutation = useMutation({
    mutationFn: data => {
      return accountApi.change_password(data)
    },
    cacheTime: Infinity,
  })

  const handleOnSubmit = async (values: any) => {
    showLoading()
    mutation.mutate(values, {
      onError: e => {
        hideLoading()
        handleSetError(e, t('messages.error.update'), setError as any)
      },
      onSuccess: e => {
        hideLoading()
        toast.success(`${e.message}`)
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{t('pages.change_password.title')}</title>
      </Helmet>
      <div className="border-b top-0 border-gray-200 sm:flex sm:items-center sm:justify-between">
        <Breadcrumb />
      </div>
      <div className="flex min-h-full items-center justify-center py-14 px-4 sm:px-6 lg:px-8 ">
        <div className="mt-10 w-full max-w-md space-y-8 p-10 space-y-6 rounded-2xl border border-solid border-[#E5E7EB] box-border shadow-2xl">
          <form
            onSubmit={handleSubmit(handleOnSubmit)}
            className="mt-8 space-y-6"
          >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md">
              <InputControl
                control={control}
                type="password"
                name="current_password"
                label={t('label.login.password')}
              />
            </div>

            <div className="-space-y-px rounded-md">
              <InputControl
                control={control}
                type="password"
                name="new_password"
                label={t('label.password.new_password')}
              />
            </div>
            <div className="-space-y-px rounded-md">
              <InputControl
                control={control}
                type="password"
                name="new_confirm_password"
                label={t('label.password.confirm_password')}
              />
            </div>

            <div>
              <Button
                type="submit"
                variant="default"
                className="!ml-0 flex justify-center w-full"
              >
                {t('common.button.change_password')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
