import { useTranslation } from 'react-i18next'

interface ColleagueResultTableProps {
  colleagueRate: number
  averageColleague: number
}

export default function ColleagueResultTable(props: ColleagueResultTableProps) {
  const { colleagueRate, averageColleague } = props
  const { t } = useTranslation()

  return (
    <div className="evaluation-type flex flex-col w-full pb-10">
      <div className="flex">
        <h2 className="">
          {t('common.label.colleague')} ({colleagueRate}%)
        </h2>

        <div className="ml-10 flex flex-col ">
          <h2 className="font-bold">{averageColleague.toFixed(2)}</h2>
        </div>
      </div>
    </div>
  )
}