import { useQuery } from '@tanstack/react-query'
import { Breadcrumb } from 'app/components/BreadCrumb'
import { profileApi } from 'app/services/api/profileApi'
import { useNavigate } from 'react-router-dom'
import ViewEmployee from './components/ViewEmployee'

export default function ViewProfile() {
  const navigate = useNavigate()
  // fetch data employees
  const { data: employee, isLoading } = useQuery(
    ['employee'],
    () => profileApi.getEmployee(),
    {
      refetchOnWindowFocus: false,
      onError: () => navigate('/profile'),
    },
  )

  if (isLoading || !employee) return ''
  return (
    <>
      <div className="border-b top-0 absolute border-gray-200 sm:flex sm:items-center sm:justify-between">
        <Breadcrumb />
      </div>
      <ViewEmployee employee={employee} />
    </>
  )
}
