export const ROUTES = {
  HOME: {
    INDEX: '/',
    EVALUATION: {
      INDEX: '/my-evaluation-list',
      EDIT: ':id'
    },
    MY_DETAIL_RESULT: {
      INDEX: '/my-detail-result',
      DETAIL: ':id'
    }
  },
  AUTH: {
    INDEX: '/',
    LOGIN: '/login',
    REDIRECT_LOGIN: '/callback',
    FORGET_PASSWORD: {
      INDEX: '/forget-password',
      RESET: '/reset-password'
    },
  },
  ABOUT: '/about',
  PROFILE: {
    INDEX: '/profile',
    EDIT: '/profile/edit'
  },
  PASSWORD: {
    INDEX: '/change-password',
  },
  TIMESHEET: {
    INDEX: '/time-sheet',
  },

  LEAVE: {
    INDEX: '/leave',
    CREATE: '/leave/create'
  },

  OVER_TIME: {
    INDEX: '/over-time',
    CREATE: '/over-time/create'
  },
  SKILL: {
    INDEX: "/skill",
    CREATE: "/skill/create",
    EDIT: "/skill/update"
  },
  NOT_FOUND: '*'
}
