import { Employee } from 'app/types/employee'
import { useForm } from 'react-hook-form'
import { DateFormat } from 'utils/helper'
import CUREmployee, { getDataPost } from '../CUREmployee'
import HeaderProfileEmployee from '../HeaderProfileEmployee'
import './index.css'

export default function ViewEmployee(props: { employee: Employee }) {
  const { employee } = props
  const { control, getValues, setValue } = useForm({
    defaultValues: getDataPost(employee),
  })

  return (
    <>
      <div>
        <div className="relative w-auto pointer-events-none p-12">
          <div className="mt-10 border-none shadow-md relative flex flex-col pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div className="relative">
              <div className="flex py-4">
                <div className="bg-white w-full mx-auto pb-8 px-6 flex justify-between gap-3">
                  <div className="flex flex-col w-full">
                    {/* Profile */}
                    <HeaderProfileEmployee
                      avatar={employee?.avatar as string}
                      birthday={employee.birthday ? DateFormat(employee.birthday) : ''}
                      id={employee.id}
                      email={employee.account.email || ''}
                      full_name={
                        employee.first_name + ' ' + employee.last_name || ''
                      }
                      phone={employee.phone_number?.toString() || ''}
                    />
                    {/* Body content */}
                    <div className="mb-4"></div>
                    {
                      <CUREmployee
                        control={control}
                        disabled={true}
                        getValues={getValues}
                        setValue={setValue}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
