import {
  Table,
  TableBody,
  TableBodyContent,
  TableBodyTr,
  TableHeader,
  TableHeaderContent,
} from 'app/components/Table'

export default function ShowStatistic() {
  const time_number_titles = [
    {
      name: 'Số giờ làm việc thực tế',
      value: 40,
    },
    {
      name: 'Số giờ làm thêm thực tế',
      value: 40,
    },
    {
      name: 'Số giờ làm việc trong tuần',
      value: 40,
    },
  ]
  const info_titles = [
    {
      name: 'Thời gian',
      value: '1/2023',
    },
    {
      name: 'Mã nhân viên',
      value: '0080',
    },
    {
      name: 'Nhóm',
      value: 'RISKTAKER',
    },
  ]
  const data_basic_titles = [
    {
      name: 'Số ngày làm việc thực tế',
      value: 230,
    },
    {
      name: 'Số ngày làm việc trong tuần',
      value: 5,
    },
    {
      name: 'Số ngày vắng mặt',
      value: 3,
    },
  ]
  const rest_day_titles = [
    {
      name: 'Nghỉ có lương',
      value: 2,
    },
    {
      name: 'Nghỉ bù',
      value: 5,
    },
    {
      name: 'Nghỉ hoán đổi',
      value: 3,
    },
  ]
  return (
    <div className="flex flex-col transition duration-150 ease-out transition-2">
      <div className="flex flex-row w-full gap-3 py-3">
        <div className="flex w-full">
          <Table>
            <TableHeader>
              <TableHeaderContent title="Thông tin người dùng" />
              <TableHeaderContent />
            </TableHeader>
            <TableBody>
              {info_titles.map((info, index) => (
                <TableBodyTr>
                  <TableBodyContent key={index}>{info.name}</TableBodyContent>
                  <TableBodyContent>{info.value}</TableBodyContent>
                </TableBodyTr>
              ))}
            </TableBody>
          </Table>
        </div>
        <div className="flex w-full">
          <Table>
            <TableHeader>
              <TableHeaderContent title="Dữ liệu chấm công cơ bản" />
              <TableHeaderContent />
            </TableHeader>
            <TableBody>
              {data_basic_titles.map((data, index) => (
                <TableBodyTr>
                  <TableBodyContent key={index}>{data.name}</TableBodyContent>
                  <TableBodyContent>{data.value}</TableBodyContent>
                </TableBodyTr>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
      <div className="flex flex-row w-full gap-3 py-3">
        <div className="flex w-full">
          <Table>
            <TableHeader>
              <TableHeaderContent title="Số giờ làm việc" />
              <TableHeaderContent />
            </TableHeader>
            <TableBody>
              {time_number_titles.map((time, index) => (
                <TableBodyTr>
                  <TableBodyContent key={index}>{time.name}</TableBodyContent>
                  <TableBodyContent>{time.value}</TableBodyContent>
                </TableBodyTr>
              ))}
            </TableBody>
          </Table>
        </div>
        <div className="flex w-full">
          <Table>
            <TableHeader>
              <TableHeaderContent title="Số ngày nghỉ còn lại tính đến hôm nay" />
              <TableHeaderContent />
            </TableHeader>
            <TableBody>
              {rest_day_titles.map((day, index) => (
                <TableBodyTr>
                  <TableBodyContent key={index}>{day.name}</TableBodyContent>
                  <TableBodyContent>{day.value}</TableBodyContent>
                </TableBodyTr>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  )
}
