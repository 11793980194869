import { Button } from 'app/components/Button'
import { InputControl } from 'app/components/FormControl'
import { ListParams } from 'app/types/common'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useLoading } from 'app/context/loading'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'app/config/routes'
import { useAppDispatch } from 'app/hooks'
import { sendEmail } from 'features/auth/authSlice'

export default function ForgetPasswordPage() {
  const { t } = useTranslation()
  const { showLoading, hideLoading } = useLoading()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const validations = yup.object().shape({
    email: yup
      .string()
      .required(
        t('validation.required', {
          field: t('label.login.email'),
        }),
      )
      .email(t('validation.email')),
  })

  const initialValues: ListParams = {
    email: '',
  }

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(validations),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: initialValues,
  })

  const handleOnSubmit = async (data: ListParams) => {
    showLoading()
    const response = await dispatch(sendEmail(data))
    hideLoading()
    if (response.payload.message) {
      toast.success(response.payload.message)
      navigate(ROUTES.AUTH.FORGET_PASSWORD.RESET)
    } else {
      console.log(response.payload.meta.error_message);
    }
  }

  return (
    <>
      <Helmet>
        <title>{t('pages.forget_password.title')}</title>
      </Helmet>
      <div className="flex min-h-full items-center justify-center py-14 px-4 sm:px-6 lg:px-8 ">
        <div className="w-full max-w-md space-y-8 p-10 space-y-6 rounded-2xl border border-solid border-[#E5E7EB] box-border shadow-2xl">
          <div>
            <img
              className="mx-auto w-auto h-full"
              src="/assets/img/main-logo.png"
              alt="KZ PORTAL"
            />
          </div>
          <p className="sub-title mt-0 mb-4 tracking-[0px] text-center">
            {t('pages.forget_password.description')}
          </p>
          <form
            onSubmit={handleSubmit(handleOnSubmit)}
            className="mt-8 space-y-6"
          >
            <div className="-space-y-px rounded-md">
              <div className="mb-3">
                <InputControl
                  control={control}
                  name="email"
                  label={t('common.label.email')}
                />
              </div>
            </div>
            <div>
              <Button
                type="submit"
                variant="default"
                className="!ml-0 flex justify-center w-full mt-10"
              >
                {t('common.button.send_code')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
