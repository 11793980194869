import { forwardRef, InputHTMLAttributes, LegacyRef } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  name?: string
  error?: any
  icon?: any
}

export const FormInput = forwardRef(
  (props: FormInputProps, ref: LegacyRef<HTMLInputElement>) => {
    const { label, name, error, icon, className, onChange, ...inputProps } = props

    return (
      <div className="w-full">
        {label && (
          <div className="flex justify-between">
            <label
              htmlFor={name}
              className="block text-sm font-medium text-gray-700"
            >
              {label}
              {inputProps.required && (
                <span className="text-[#e74c3c] text-md"> *</span>
              )}
            </label>
          </div>
        )}
        <div className={`relative mt-1 ${error ? 'is-invalid' : ''}`}>
          <input
            ref={ref}
            name={name}
            id={name}
            onChange={onChange}
            className={`py-2 px-3 block w-full rounded-md border-solid border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${className}`}
            {...inputProps}
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            {error ? (
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            ) : (
              icon || ''
            )}
          </div>
        </div>
        {error && <p className="feedback text-left">{error.message}</p>}
      </div>
    )
  },
)
