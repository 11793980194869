import queryString from 'query-string'
import { useSearchParams } from 'react-router-dom'

const  usePagination = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const params = queryString.parse(window.location.search)

  // next and previous page
  const handlePageChange = (pageSelect: string) => {
    const page = pageSelect + 1
    setSearchParams({ ...params, page: page })
  }

  // select rows per page
  const handleRowsPerPageChange = (rows: string) => {
    setSearchParams({ ...searchParams, rows_per_page: rows })
  }
    
  return { searchParams, params, handlePageChange, handleRowsPerPageChange, setSearchParams }
}

export default usePagination
