import {
  ArrowLeftOnRectangleIcon,
  Bars4Icon,
  ClipboardDocumentListIcon,
  DocumentCheckIcon,
  InformationCircleIcon,
  KeyIcon,
  UserCircleIcon,
  RectangleStackIcon,
  SparklesIcon,
  BriefcaseIcon
} from '@heroicons/react/24/outline'
import { ROUTES } from 'app/config/routes'
import { useAppDispatch } from 'app/hooks'
import { logout } from 'features/auth/authSlice'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import './index.css'

interface Item {
  name: string
  href: string
  icon: React.ElementType
  onClick?: () => void
  child?: Item[]
}

export function MenuSidebar() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const clickLogout = async () => {
    await dispatch(logout())
    navigate(ROUTES.AUTH.LOGIN)
  }

  const navigation: Item[] = [
    {
      name: t('pages.account.title'),
      href: `#`,
      icon: UserCircleIcon,
      child: [
        {
          name: t('pages.account.info'),
          href: `${ROUTES.PROFILE.INDEX}`,
          icon: InformationCircleIcon,
        },
        {
          name: t('pages.change_password.title'),
          href: `${ROUTES.PASSWORD.INDEX}`,
          icon: KeyIcon,
        },
        {
          name: t('pages.logout.title'),
          href: `${ROUTES.AUTH.LOGIN}`,
          icon: ArrowLeftOnRectangleIcon,
          onClick: clickLogout,
        },
      ],
    },
    {
      name: t('pages.evaluation.title'),
      href: `#`,
      icon: Bars4Icon,
      child: [
        {
          name: t('pages.my-evaluation-list.title'),
          href: `${ROUTES.HOME.EVALUATION.INDEX}`,
          icon: ClipboardDocumentListIcon,
        },
        {
          name: t('pages.my-detail-result.title'),
          href: `${ROUTES.HOME.MY_DETAIL_RESULT.INDEX}`,
          icon: DocumentCheckIcon,
        },
      ],
    },
    {
      name: t('pages.request.title'),
      href: `#`,
      icon: RectangleStackIcon,
      child: [
        {
          name: t('pages.leave.title'),
          href: `${ROUTES.LEAVE.INDEX}`,
          icon: SparklesIcon,
        },
        {
          name: t('pages.over-time.title'),
          href: `${ROUTES.OVER_TIME.INDEX}`,
          icon: BriefcaseIcon,
        },
      ],
    },
    {
      name: t('pages.time-sheet.title'),
      href: `${ROUTES.TIMESHEET.INDEX}`,
      icon: ClipboardDocumentListIcon,
    }
  ]

  const [showEvaluation, setShowEvaluation] = useState(-1)

  const location = useLocation()
  const historyPath = location.pathname
  const keepMenuAfterClick = () => {
    navigation.map((itemParent, index) =>
      itemParent.child?.map(
        itemChild => historyPath === itemChild.href && setShowEvaluation(index),
      ),
    )
  }
  useEffect(() => {
    keepMenuAfterClick()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyPath])

  const handleShowEvaluation = (type: string, index: number) => {
    if (type === 'parent') {
      showEvaluation === index
        ? setShowEvaluation(-1)
        : setShowEvaluation(index)
    }
  }

  const CustomNavLink = (props: {
    item: Item
    type: string
    index: number
  }) => {
    const { item, type, index } = props
    return (
      <NavLink
        onClick={item.onClick ?? (() => handleShowEvaluation(type, index))
        }
        to={item.href}
        className={({ isActive }) =>
          'group nav-link ' +
          (isActive && !item.child ? 'active' : '') +
          (type === 'child' ? ' ml-5' : '')
        }
      >
        <item.icon className="icon group-hover:text-gray-500" />
        {item.name}
      </NavLink>
    )
  }

  return (
    <>
      {navigation.map((item, index) => {
        return (
          <div key={index}>
            <CustomNavLink type={'parent'} item={item} index={index} />

            {showEvaluation === index
              ? item.child?.map((itemChild, indexChild) => {
                return (
                  <div key={indexChild}>
                    <CustomNavLink
                      type={'child'}
                      item={itemChild}
                      index={indexChild}
                    />
                  </div>
                )
              })
              : ''}
          </div>
        )
      })}
    </>
  )
}
