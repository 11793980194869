import { InputControl } from 'app/components/FormControl'
import {
  Table,
  TableBody,
  TableBodyContent,
  TableBodyTr,
  TableHeader,
  TableHeaderContent,
} from 'app/components/Table'
import { MIN_LENGTH_COMMENT_EVALUATION } from 'app/constants/global'
import { EvaluationQuestionDetail } from 'app/types/evaluation'
import { TermDetail } from 'app/types/term'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

interface EvaluationDetailTableProps {
  termDetail?: TermDetail[]
  evaluationList: any[]
  setEvaluationList: (value: any) => void
  totalScore: number
  setTotalScore: (value: number) => void
  evaluationDetail?: EvaluationQuestionDetail[]
}

export default function EvaluationDetailTable(
  props: EvaluationDetailTableProps,
) {
  const {
    termDetail,
    evaluationList,
    setEvaluationList,
    totalScore,
    setTotalScore,
    evaluationDetail,
  } = props
  const [newEvaluationDetail, setNewEvaluationDetail] = useState<
    EvaluationQuestionDetail[]
  >([])

  useEffect(() => {
    let evaluations = {}
    termDetail?.map(value =>
      value.questions.map(
        question =>
          (evaluations = {
            ...evaluations,
            [question.id]: {
              comment: newEvaluationDetail[question.id]
                ? newEvaluationDetail[question.id].comment
                : '',
              score: newEvaluationDetail[question.id]
                ? newEvaluationDetail[question.id].score
                : 0,
              max_score: question.max_score,
              term_detail_question_id: question.id,
              id: newEvaluationDetail[question.id]
                ? newEvaluationDetail[question.id].id
                : 0,
            },
          }),
      ),
    )
    setEvaluationList(evaluations)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termDetail, newEvaluationDetail])

  const [listInputValidation, setListInputValidation] = useState<number[]>([])

  useEffect(() => {
    let evaluationTerm: EvaluationQuestionDetail[] = []
    evaluationDetail?.map(
      evaluation =>
        (evaluationTerm[evaluation.term_detail_question_id] = evaluation),
    )
    setNewEvaluationDetail([...evaluationTerm])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluationDetail])

  const onHandleChangeInput = (e: any, index: number, max_score: number) => {
    const { name, value } = e.target
    if (name === t('label.evaluation.comment')) {
      evaluationList[index].comment = value

      // validation
      if (
        evaluationList[index].max_score > 0 &&
        value.length < MIN_LENGTH_COMMENT_EVALUATION
      ) {
        if (!listInputValidation.includes(index)) {
          setListInputValidation([...listInputValidation, index])
        }
      } else {
        setListInputValidation(
          [...listInputValidation].filter(
            inputValidation => inputValidation !== index,
          ),
        )
      }
    } else {
      (+value > max_score) &&
        toast.warning(`${t('messages.error.evaluation.unable_score')}`)
      setTotalScore(+value + totalScore - evaluationList[index].score)
      evaluationList[index].score = +value
    }
    setEvaluationList(evaluationList)
  }

  return (
    <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
      {/* Table List Evaluation */}
      {termDetail?.map((value, index) => (
        <Table key={index}>
          <TableHeader>
            <TableHeaderContent
              title={`${value.criteria_name} ${
                value.max_criteria_score === 0
                  ? ''
                  : `(${t('common.label.maximum')}: ${
                      value.max_criteria_score
                    })`
              }`}
            />
            <TableHeaderContent title={''} className="text-center" />
          </TableHeader>
          <TableBody>
            {value.questions.map((question, index2) => (
              <TableBodyTr key={index2}>
                <TableBodyContent className="w-1/3">{`${question.question} ${
                  question.max_score === 0
                    ? ''
                    : `(${t('common.label.maximum')}: ${question.max_score})`
                }`}</TableBodyContent>

                <TableBodyContent className="text-center flex w-full">
                  {question.max_score !== 0 && (
                    <div>
                      <InputControl
                        className="w-4/5"
                        defaultValue={
                          newEvaluationDetail[question.id]
                            ? newEvaluationDetail[question.id].score
                            : ''
                        }
                        name={t('label.evaluation.score')}
                        label={t('label.evaluation.score')}
                        onChange={e =>
                          onHandleChangeInput(
                            e,
                            question.id,
                            question.max_score,
                          )
                        }
                        type="number"
                      />
                    </div>
                  )}
                  <div
                    className={`${
                      question.max_score === 0 ? `w-full` : `w-4/5`
                    }`}
                  >
                    <InputControl
                      defaultValue={
                        newEvaluationDetail[question.id]
                          ? newEvaluationDetail[question.id].comment
                          : ''
                      }
                      name={t('label.evaluation.comment')}
                      label={t('label.evaluation.comment')}
                      onChange={e =>
                        onHandleChangeInput(e, question.id, question.max_score)
                      }
                      className={`${
                        listInputValidation.includes(question.id) &&
                        'border border-red-700'
                      }`}
                    />
                    {listInputValidation.includes(question.id) && (
                      <p className="feedback text-red-700 text-left">{`${t(
                        'validation.min',
                        {
                          field: t('label.evaluation.comment'),
                          min: MIN_LENGTH_COMMENT_EVALUATION,
                        },
                      )}`}</p>
                    )}
                  </div>
                </TableBodyContent>
              </TableBodyTr>
            ))}
          </TableBody>
        </Table>
      ))}
    </div>
  )
}
