import { getAccessToken } from "utils/auth";
import { Navigate, Outlet } from "react-router-dom";
import { ROUTES } from "app/config/routes";

export function RedirectIfAuthenticated() {
  const isAuth = !!getAccessToken()
  
                
  return (
    isAuth
      ? <Navigate to={ROUTES.HOME.INDEX} replace />
      : <Outlet />
  );
}
