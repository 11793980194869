import { ROUTES } from 'app/config/routes'
import { Navigate, Outlet } from 'react-router-dom'
import { getAccessToken } from 'utils/auth'

export function AuthLayout() {
  // Load auth users and check redirect
  const isAuth = !!getAccessToken()

  return (
    !isAuth
      ? <Outlet />
      : <Navigate to={ROUTES.HOME.INDEX} replace />
  )
}