import { API_URL } from 'app/config'
export const GENDER_OPTION = ['Nam', 'Nữ']
export const GENDER_SEARCH = [{ male: 0, female: 1 }]

export const SELECT_GENDER_OPTION = [
  { value: '', label: 'Tất cả' },
  { value: '0', label: GENDER_OPTION[0] },
  { value: '1', label: GENDER_OPTION[1] },
]

export const SELECT_STATUS_EVENT_OPTION = [
  { value: '0', label: 'Lặp lại mỗi năm' },
  { value: '1', label: 'Chỉ một năm' },
]

// sheet name excel file
export const DATA_EXCELS = {
  employee: {
    import: {
      sheets: ['data', 'job titles', 'banks'],
    },
    export: {
      template: {
        file_path: API_URL + '/employees-export-template',
      },
      file_path: API_URL + '/employees-export',
    },
  },
}

export const EVALUATION_TYPE_SELF = 'SELF'
export const EVALUATION_TYPE_COMPANY = 'COMPANY'
export const EVALUATION_TYPE_COLLEAGUE = 'COLLEAGUE'

export const QUESTION_TYPE: { [key: string]: string } = {
  1: EVALUATION_TYPE_COMPANY,
  2: EVALUATION_TYPE_SELF,
  3: EVALUATION_TYPE_COLLEAGUE,
}

export const EVALUATION_TYPE: { [key: string]: number } = {
  EVALUATION_TYPE_COMPANY: 1,
  EVALUATION_TYPE_SELF: 2,
  EVALUATION_TYPE_COLLEAGUE: 3
}

export const EVALUATION_TYPE_VIETNAMESE: { [key: string]: string } = {
  'Company': 'Công ty',
  'Self': 'Tự đánh giá',
  'Colleague': 'Đồng nghiệp'
}

export const MIN_LENGTH_COMMENT_EVALUATION = 50
export const MIN_LENGTH_COLLEAGUE_COMMENT = 100
export const EVALUATION_COLLEAGUE_MAX_SCORE = 100

export const SELECT_MONTH_OPTION = [
  { value: 1, label: '01' },
  { value: 2, label: '02' },
  { value: 3, label: '03' },
  { value: 4, label: '04' },
  { value: 5, label: '05' },
  { value: 6, label: '06' },
  { value: 7, label: '07' },
  { value: 8, label: '08' },
  { value: 9, label: '09' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
]

export const SELECT_HOUR_OPTION = [
  { value: 0, label: '00' },
  { value: 1, label: '01' },
  { value: 2, label: '02' },
  { value: 3, label: '03' },
  { value: 4, label: '04' },
  { value: 5, label: '05' },
  { value: 6, label: '06' },
  { value: 7, label: '07' },
  { value: 8, label: '08' },
  { value: 9, label: '09' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
  { value: 13, label: '13' },
  { value: 14, label: '14' },
  { value: 15, label: '15' },
  { value: 16, label: '16' },
  { value: 17, label: '17' },
  { value: 18, label: '18' },
  { value: 19, label: '19' },
  { value: 20, label: '20' },
  { value: 21, label: '21' },
  { value: 22, label: '22' },
  { value: 23, label: '23' },
  { value: 24, label: '24' },
]

export const SELECT_MINUTE_OPTION = [
  { value: 0, label: '00' },
  { value: 1, label: '30' },
]